import React, { useState, useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import Overview from "./overview.png"
const Test = () => {
  const [reviews, setReviews] = useState([
  {
    link: 'https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURXNEtmSkxREAE!2m1!1s0x0:0x1c5b13afd8022c73!3m1!1s2@1:CIHM0ogKEICAgIDW4KfJLQ%7CCgwI2ruykQYQgIb7-gE%7C?hl=en-US',
    rating: 5,
    date: '2 years ago',
    iso_date: '2022-03-12T13:19:22Z',
    iso_date_of_last_edit: '2022-03-12T13:19:22Z',
    source: 'Google',
    review_id: 'ChZDSUhNMG9nS0VJQ0FnSURXNEtmSkxREAE',
    user: {
      name: 'Lois Canavan',
      link: 'https://www.google.com/maps/contrib/116763418730671097850?hl=en-US',
      contributor_id: '116763418730671097850',
      thumbnail: 'https://lh3.googleusercontent.com/a/ACg8ocL5gD-0GBGXYLWsOd8BklQOHQ2Wio--hoZwtZTbg1DGdZGDCQ=s120-c-rp-mo-br100',
      reviews: 3,
      photos: 0
    },
    snippet: 'Dr Fikret is an incredible person! He gave me the biggest hug when I first saw him and I really needed that. I wish all doctors were as friendly as he is. He came to see me several times after the surgery explaining that everything went well.\n' +
      'I’m only 3 days post op and I feel pretty great already. Thank you so much xx',
    extracted_snippet: {
      original: 'Dr Fikret is an incredible person! He gave me the biggest hug when I first saw him and I really needed that. I wish all doctors were as friendly as he is. He came to see me several times after the surgery explaining that everything went well.\n' +
        'I’m only 3 days post op and I feel pretty great already. Thank you so much xx'
    },
    likes: 1
  },
  {
    link: 'https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURLbkstLVN3EAE!2m1!1s0x0:0x1c5b13afd8022c73!3m1!1s2@1:CIHM0ogKEICAgIDKnK--Sw%7CCgwIm5_BhAYQ-OuVtgI%7C?hl=en-US',
    rating: 5,
    date: '3 years ago',
    iso_date: '2021-05-03T19:31:39Z',
    iso_date_of_last_edit: '2021-05-03T19:31:39Z',
    source: 'Google',
    review_id: 'ChZDSUhNMG9nS0VJQ0FnSURLbkstLVN3EAE',
    user: {
      name: 'Amy akdag',
      link: 'https://www.google.com/maps/contrib/102540236645236347886?hl=en-US',
      contributor_id: '102540236645236347886',
      thumbnail: 'https://lh3.googleusercontent.com/a/ACg8ocL-Ev056OFfbHFYm0e-OKPo3cBPDWX5hdILMwADUjN3EFEMVw=s120-c-rp-mo-br100',
      reviews: 4,
      photos: 1
    },
    snippet: 'I had my gastric sleeve operation 7 weeks ago today 15/3/21.  I was very nervous and am an anxious person but the moment I met Dr. Fikret I felt at ease, he is one of the most genuine people I have met and I felt so happy I had him doing my surgery.\n' +
      'He explained everything and always took time to make sure I was ok and if I had questions. He visited me daily during my stay and I still have great support even now back in the uk should I have questions.\n' +
      'My surgery went very well, my scars are very tidy and I have lost more than 2 stone so far.  I really couldn’t be happier with the care and service I received and would recommend him to anyone looking into this surgery.',
    extracted_snippet: {
      original: 'I had my gastric sleeve operation 7 weeks ago today 15/3/21.  I was very nervous and am an anxious person but the moment I met Dr. Fikret I felt at ease, he is one of the most genuine people I have met and I felt so happy I had him doing my surgery.\n' +
        'He explained everything and always took time to make sure I was ok and if I had questions. He visited me daily during my stay and I still have great support even now back in the uk should I have questions.\n' +
        'My surgery went very well, my scars are very tidy and I have lost more than 2 stone so far.  I really couldn’t be happier with the care and service I received and would recommend him to anyone looking into this surgery.'
    },
    likes: 1
  },
  {
    link: 'https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUR5eWIyWG13RRAB!2m1!1s0x0:0x1c5b13afd8022c73!3m1!1s2@1:CIHM0ogKEICAgIDyyb2XmwE%7CCgwIw6LGhAYQ8MyfmQE%7C?hl=en-US',
    rating: 5,
    date: '3 years ago',
    iso_date: '2021-03-12T01:04:19Z',
    iso_date_of_last_edit: '2021-05-04T18:24:03Z',
    source: 'Google',
    review_id: 'ChdDSUhNMG9nS0VJQ0FnSUR5eWIyWG13RRAB',
    user: {
      name: 'Kate Amy Rankin',
      link: 'https://www.google.com/maps/contrib/108831205412336126814?hl=en-US',
      contributor_id: '108831205412336126814',
      thumbnail: 'https://lh3.googleusercontent.com/a/ACg8ocJuQuAl79tS-ABiJ3qZOEZCXcbhCcHa2Gvz8UoJddvZQkhekg=s120-c-rp-mo-br100',
      reviews: 4,
      photos: 5
    },
    snippet: 'I had gastric sleeve surgery with Dr Fikret on 14/09/2020 at gozde hospital in kusadasi.\n' +
      'Amazon surgeon. He came to see me every single day.His medical knowledge is amazing.\n' +
      '\n' +
      'I’ve never met a surgeon like Dr Fikret, he is so caring & really looks after his patients.\n' +
      'He really has saved my life more than he will ever know.\n' +
      '\n' +
      'I’m 71.5lbs down, 5/6 dress sizes down and feel the healthiest I have ever been!\n' +
      '\n' +
      'I 100% recommend Dr Fikret! I promise you will be in good hands.',
    extracted_snippet: {
      original: 'I had gastric sleeve surgery with Dr Fikret on 14/09/2020 at gozde hospital in kusadasi.\n' +
        'Amazon surgeon. He came to see me every single day.His medical knowledge is amazing.\n' +
        '\n' +
        'I’ve never met a surgeon like Dr Fikret, he is so caring & really looks after his patients.\n' +
        'He really has saved my life more than he will ever know.\n' +
        '\n' +
        'I’m 71.5lbs down, 5/6 dress sizes down and feel the healthiest I have ever been!\n' +
        '\n' +
        'I 100% recommend Dr Fikret! I promise you will be in good hands.'
    },
    likes: 0
  },
  {
    link: 'https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURLb3VpYl93RRAB!2m1!1s0x0:0x1c5b13afd8022c73!3m1!1s2@1:CIHM0ogKEICAgIDKouib_wE%7CCgwIuoHFoQYQ-JLdmgI%7C?hl=en-US',
    rating: 5,
    date: 'a year ago',
    iso_date: '2021-05-05T06:50:00Z',
    iso_date_of_last_edit: '2023-04-08T10:23:54Z',
    source: 'Google',
    review_id: 'ChdDSUhNMG9nS0VJQ0FnSURLb3VpYl93RRAB',
    user: {
      name: 'Carol McDonald',
      link: 'https://www.google.com/maps/contrib/100493641321004573612?hl=en-US',
      contributor_id: '100493641321004573612',
      thumbnail: 'https://lh3.googleusercontent.com/a-/ALV-UjWykBj7MALn_s2_D19DLsVwH_I3CHexHx6bKTmIUmBwgko9RHcZzw=s120-c-rp-mo-br100',
      reviews: 2,
      photos: 0
    },
    snippet: 'I had my operation over two years ago and can honestly say the care I received from Dr Fikret and all the staff was definitely 5 Star. I had a problem with my blood pressure being high and Dr Fikret visited regularly, always with a big smile on his face. My scars are tiny and almost disappeared and have had no problems post surgery. I would definitely recommend Dr Fikret and the getslimteam. 😀',
    extracted_snippet: {
      original: 'I had my operation over two years ago and can honestly say the care I received from Dr Fikret and all the staff was definitely 5 Star. I had a problem with my blood pressure being high and Dr Fikret visited regularly, always with a big smile on his face. My scars are tiny and almost disappeared and have had no problems post surgery. I would definitely recommend Dr Fikret and the getslimteam. 😀'
    },
    likes: 0
  },
  {
    link: 'https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURLZ3ZYT1V3EAE!2m1!1s0x0:0x1c5b13afd8022c73!3m1!1s2@1:CIHM0ogKEICAgIDKgvXOUw%7CCgwI1snGhAYQoPKnyAE%7C?hl=en-US',
    rating: 5,
    date: '3 years ago',
    iso_date: '2021-05-04T19:47:34Z',
    iso_date_of_last_edit: '2021-05-04T19:47:34Z',
    source: 'Google',
    review_id: 'ChZDSUhNMG9nS0VJQ0FnSURLZ3ZYT1V3EAE',
    user: {
      name: 'Vicci Kaighan',
      link: 'https://www.google.com/maps/contrib/116612871916569513629?hl=en-US',
      contributor_id: '116612871916569513629',
      thumbnail: 'https://lh3.googleusercontent.com/a/ACg8ocIfgv2xXWJU-dfHWT800ro6UiXkznzNqNJ6MX0UC0Em1hk4kQ=s120-c-rp-mo-br100',
      reviews: 4,
      photos: 4
    },
    snippet: "Absolutely could not recommend Dr Fikret more. He is the most amazing Dr I have ever met. From the moment I met him, I knew I chose the right surgeon. Dr Fikret takes the time to talk you through everything,  if you have any questions or worries he is there to answer all. After the surgery he checks on you daily, and he literally lights up the room with his energy. I can't thank him enough for giving me a new lease of life.",    
    extracted_snippet: {
      original: "Absolutely could not recommend Dr Fikret more. He is the most amazing Dr I have ever met. From the moment I met him, I knew I chose the right surgeon. Dr Fikret takes the time to talk you through everything,  if you have any questions or worries he is there to answer all. After the surgery he checks on you daily, and he literally lights up the room with his energy. I can't thank him enough for giving me a new lease of life."  
    },
    likes: 0
  },
  {
    link: 'https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURLZ3RHWmxBRRAB!2m1!1s0x0:0x1c5b13afd8022c73!3m1!1s2@1:CIHM0ogKEICAgIDKgtGZlAE%7CCgwIg7LGhAYQgLeA5gE%7C?hl=en-US',
    rating: 5,
    date: '3 years ago',
    iso_date: '2021-05-04T18:57:07Z',
    iso_date_of_last_edit: '2021-05-04T18:57:07Z',
    source: 'Google',
    review_id: 'ChdDSUhNMG9nS0VJQ0FnSURLZ3RHWmxBRRAB',
    user: {
      name: 'R H',
      link: 'https://www.google.com/maps/contrib/105781763636770694214?hl=en-US',
      contributor_id: '105781763636770694214',
      thumbnail: 'https://lh3.googleusercontent.com/a/ACg8ocLj8eCVmwdh7gJLxdfhuFJGedNOtAHyRxJSGbFsY0HriwzzAA=s120-c-rp-mo-br100',
      reviews: 5,
      photos: 0
    },
    snippet: 'Dr Fikret instills confidence. I hadn’t ‘chosen’ my surgeon, but I was impressed with his knowledge and care for me and others who had the operation at the same time. He is committed to each patient individually. Nothing is too much trouble. His regular visits after the recovery was reassuring. Would recommend him as a surgeon .',
    extracted_snippet: {
      original: 'Dr Fikret instills confidence. I hadn’t ‘chosen’ my surgeon, but I was impressed with his knowledge and care for me and others who had the operation at the same time. He is committed to each patient individually. Nothing is too much trouble. His regular visits after the recovery was reassuring. Would recommend him as a surgeon .'
    },
    likes: 0
  },
  {
    link: 'https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURLZ3VIUGt3RRAB!2m1!1s0x0:0x1c5b13afd8022c73!3m1!1s2@1:CIHM0ogKEICAgIDKguHPkwE%7CCgwI8q_GhAYQ8LeplQI%7C?hl=en-US',
    rating: 5,
    date: '3 years ago',
    iso_date: '2021-05-04T18:52:34Z',
    iso_date_of_last_edit: '2021-05-04T18:52:34Z',
    source: 'Google',
    review_id: 'ChdDSUhNMG9nS0VJQ0FnSURLZ3VIUGt3RRAB',
    user: {
      name: 'Lesley Shillingford',
      link: 'https://www.google.com/maps/contrib/101732074646503620507?hl=en-US',
      contributor_id: '101732074646503620507',
      thumbnail: 'https://lh3.googleusercontent.com/a/ACg8ocJ6YYFyAcZwUllyZYiip9lCSia0Rg7vdxc6hFUqjw7ObhGNlw=s120-c-rp-mo-br100',
      reviews: 4,
      photos: 6
    },
    snippet: 'Dr Fikret is the perfect gentleman.  He just has a way of putting your mind at rest. He has great knowledge of gastric sleeve and my operation went without a hitch. Very small scars that I am sure will fade even further\n' +
      'Thankyou Dr Fikret x',
    extracted_snippet: {
      original: 'Dr Fikret is the perfect gentleman.  He just has a way of putting your mind at rest. He has great knowledge of gastric sleeve and my operation went without a hitch. Very small scars that I am sure will fade even further\n' +
        'Thankyou Dr Fikret x'
    },
    likes: 0
  },
  {
    link: 'https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURLZ3M3WThRRRAB!2m1!1s0x0:0x1c5b13afd8022c73!3m1!1s2@1:CIHM0ogKEICAgIDKgs7Y8QE%7CCgwIg6LGhAYQqMD30wE%7C?hl=en-US',
    rating: 5,
    date: '3 years ago',
    iso_date: '2021-05-04T18:22:59Z',
    iso_date_of_last_edit: '2021-05-04T18:22:59Z',
    source: 'Google',
    review_id: 'ChdDSUhNMG9nS0VJQ0FnSURLZ3M3WThRRRAB',
    user: {
      name: 'Olivia Dyson',
      link: 'https://www.google.com/maps/contrib/107072208196160138112?hl=en-US',
      contributor_id: '107072208196160138112',
      thumbnail: 'https://lh3.googleusercontent.com/a-/ALV-UjWTmaF42l3DrQFtQxKzZKwYQLRGXcyviiiZpsXHt32eDwbQr03jrw=s120-c-rp-mo-br100',
      local_guide: true,
      reviews: 7,
      photos: 1
    },
    snippet: 'I had my gastric sleeve with Dr Fikret in Jan 2021, my experience was perfect from start to finish, what a lovely doctor with a sunny personality, friendly face and great bedside manner.\n' +
      '\n' +
      'I’ve have had no complications and am almost at my target weight in less than 4 months, my only regret was not doing it sooner.',
    extracted_snippet: {
      original: 'I had my gastric sleeve with Dr Fikret in Jan 2021, my experience was perfect from start to finish, what a lovely doctor with a sunny personality, friendly face and great bedside manner.\n' +
        '\n' +
        'I’ve have had no complications and am almost at my target weight in less than 4 months, my only regret was not doing it sooner.'
    },
    likes: 0
  }
  ]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch("http://localhost:5000/reviews");
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setReviews(data.slice(0, 20));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching reviews:", error);
        setLoading(false);
      }
    };
    fetchReviews();
  }, []);

  const truncateText = (text, limit = 500) =>
    text.length > limit ? text.substring(0, limit) + "..." : text;

  return (
    <div style={{ fontFamily: "Arial, sans-serif", backgroundColor: "#f4f4f9", color: "#333" }}>
    <section
      style={{
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#f4f4f9",
        color: "#333",
        padding: "20px 0px"
      }}
    >
      {/* Main Container */}
      <Box
        component="section"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // ✅ Stacks on mobile, row on larger screens
          alignItems: "center",
          justifyContent: "center",
          background:
            "linear-gradient(150deg,rgb(0, 0, 0),rgb(15, 116, 119) 10%, #5151e5,rgb(0, 0, 0) 100%)",
          padding: "20px",
          borderRadius: "1px",
          color: "white",
          marginTop: { xs: "70px", sm: "100px" },
        }}
      >
        {/* Left Part - Image */}
        <figure
          style={{
            flex: "1",
            padding: "20px",
            textAlign: "left",
            maxWidth: "500px",
          }}
        >
          <img
            src={Overview}
            alt="Profile"
            style={{
              width: "100%",
              height: "40vh",
              borderRadius: "10px",
              maxWidth: "300px",
            }}
          />
        </figure>

        {/* Middle Part - Quote */}
        <Box
      component="article"
      sx={{
        flex: 2,
        padding: { xs: "20px", sm: "40px" }, // ✅ Adjusts padding per screen size
        marginLeft: { xs: "10px", sm: "40px" }, 
        marginRight: { xs: "10px", sm: "80px" }, // ✅ Adds margin dynamically
        textAlign: { xs: "center", sm: "center" }, // ✅ Centers on mobile, left-aligns on larger screens
        display: "block", // ✅ Ensures proper rendering
      }}
    >
      <blockquote
        style={{
          fontSize: "16px",
          lineHeight: "1.6",
          fontStyle: "italic",
        }}
      >
        "The good physician treats the disease; the great physician treats
        the patient who has the disease"
      </blockquote>

      <Typography
        component="figcaption"
        sx={{
          fontWeight: "bold",
          textAlign: { xs: "center", sm: "center" },
          marginTop: "5px",
        }}
      >
        William Osler
      </Typography>
    </Box>
        {/* Right Part - Statistics */}
        <Box
          component="aside"
          sx={{
            flex: "1",
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          <Typography sx={{ marginTop:{xs:"8vh", sm:"0"}, fontSize: { xs: "36px", sm: "42px" }, fontWeight: "bold",}}>
            20000+
          </Typography>
          <Typography sx={{fontSize: { xs: "1.3rem", sm: "1rem" } }}>
          Patients with wonderful experiences
           </Typography>
           <Typography sx={{fontSize: { xs: "1.3rem", sm: "1rem" } }}>
            with positive feedbacks
            </Typography>
            <Typography sx={{ fontSize: { xs: "1.3rem", sm: "1rem" }} }>
            with positive testimonials
            </Typography>
            <Typography sx={{ fontSize: { xs: "1.3rem", sm: "1rem" } }}>
            with 100+ Google Reviews
            </Typography>
            <Typography sx={{ fontSize: { xs: "1.3rem", sm: "1rem" } }}>
            with 100+
            <span style={{ color: "#FFD700", fontWeight: "bold", marginLeft: "5px" }}>
              ★★★★★
            </span>
          </Typography>

          {/* Read Google Reviews Link */}
         <Typography
  component="a"
  href="https://g.co/kgs/gauFGCR"
  target="_blank"
  rel="nofollow"
  sx={{
    display: "inline-block",
    marginTop: "30px",
    color: "rgba(255, 255, 255, 0.8)",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: { xs: "1.8rem", sm: "1.2rem" }, // ✅ Responsive font sizes
    transition: "font-size 0.2s ease, color 0.2s ease",
    "&:hover": {
      fontSize: { xs: "1.9rem", sm: "1.3rem" }, // ✅ Responsive hover effect
      color: "#FFD700",
    },
  }}
>
  Read Google Reviews →
</Typography>

        </Box>
      </Box>
    </section>

      <section style={{ width: "100%", padding: "50px 20px", textAlign: "center" }}>
        <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: "20px" }}>
          Google Reviews
        </Typography>

        {loading ? (
          <CircularProgress color="primary" />
        ) : (
          <Box sx={{ maxWidth: "1200px", margin: "0 auto" }}>
            {reviews.reduce((rows, review, index) => {
              if (index % 2 === 0) rows.push([]);
              rows[rows.length - 1].push(review);
              return rows;
            }, []).map((row, rowIndex) => (
              <Box
                key={rowIndex}
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
                  gap: "20px",
                  padding: "20px",
                }}
              >
                {row.map((review, colIndex) => (
                  <Box
                    key={colIndex}
                    sx={{
                      padding: "20px",
                      borderRadius: "10px",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      minHeight: "250px",
                      backgroundColor:
                        (rowIndex % 2 === 0 && colIndex === 0) || (rowIndex % 2 !== 0 && colIndex === 1)
                          ? "#fff"
                          : "rgb(232, 248, 252)",
                      color:
                        (rowIndex % 2 === 0 && colIndex === 0) || (rowIndex % 2 !== 0 && colIndex === 1)
                          ? "#555"
                          : "#555",
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: "bold" }}>{review.user?.name || "Anonymous"}</Typography>
                      <Typography sx={{ color: "#FFD700" }}>{"⭐".repeat(Math.round(review.rating || 0))}</Typography>
                    </Box>
                    <Typography
                      sx={{
                        marginTop: "10px",
                        flexGrow: 1,
                        textAlign: truncateText(review.snippet).length < 150 ? "left" : "left",
                        paddingBottom: "30px",
                      }}
                    >
                      {truncateText(review.snippet)}
                    </Typography>
    {review.link && (
  <Box
    component="a"
    href={review.link}
    target="_blank"
    rel="nofollow"
    sx={{
      marginTop: "auto",
      color: "rgba(1, 22, 23, 0.6)",
      fontWeight: "bold",
      textDecoration: "none",
      transition: "font-size 0.2s ease, color 0.2s ease",
      "&:hover": {
        fontSize: "1.1rem", // ✅ Slightly increase text size on hover
        color: "#195D8A", // ✅ Change text color on hover
      },
    }}
  >
    Read Full Google Review →
  </Box>
)}

                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        )}
      </section>
    </div>
  );
};

export default Test;
