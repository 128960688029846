import React from "react";
import { Box, Typography } from "@mui/material";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import FA from "./F&Q.png";
import Background from "../kusadasi/leaf.jpg";
import Header from "../global/header";
import About from "../global/about";

const FAQ = () => {
    const sectionStyle = {
        height: "100%", // Full viewport height
        width: "100%", // Full width
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        marginBottom: "2px"
      };
  return (      
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        backgroundColor: "#f4f4f9",
        color: "#333",
      }}
    >
      <Header
      backgroundImage={Background}
      faqImage={FA}
      headerText="Frequently Asked Questions (FAQ)"
    />
      <About
  title="About Questions"
  text="Are you considering weight loss surgery in Turkey and have questions? 
        We are here to answer all of your concerns. Knowing more facts helps 
        you decide what is best for you and gain the confidence to take the 
        next step. That’s why we have prepared a comprehensive frequently asked 
        questions page about traveling, surgery, Turkey, and much more."
/>

        <section style={{ ...sectionStyle, backgroundColor: "#f9f9f9",textAlign: "left", paddingBottom: "40px"}}>
        <Box
  sx={{
    display: "flex",
    flexDirection: "column",
    alignItems: { xs: "center", md: "flex-start" }, // Centered on mobile, left-aligned on larger screens
    paddingLeft: { xs: "0px", sm: "40px", md: "80px", lg: "1.5ch" }, // Adjust padding instead of marginLeft
    width: "100%", // Ensures full-width alignment control
  }}
>
  <Typography
    variant="h3"
    sx={{
      fontWeight: "bold",
      marginBottom: "16px",
      fontSize: { xs: "1.8rem", md: "2.5rem", lg: "3rem" }, // Responsive font size
      textAlign: { xs: "center", md: "left" }, // Centered on small screens, left-aligned on larger screens
    }}
  >
    General Questions
  </Typography>
</Box>

        {/* MDB Accordion Section */}
        <MDBContainer className="mt-5" style={{ maxWidth: "1500px", width: "100%" }}>
          <MDBAccordion alwaysOpen initialActive={1}>
            <MDBAccordionItem  style = {{color: "black"}} collapseId={1} headerTitle={<span style = {{color:"#555"}}> Why Choose Op. Dr. Fikret Beyaz for Bariatric Surgery in Turkey?</span>}>
              <strong>At Gozde Kusadasi Hospital</strong>, we provide world-class bariatric surgery in Turkey with an expert surgeon who has performed over 15,000 successful weight loss procedures.  
              <ul>
                <li>Gastric sleeve surgery by an experienced surgeon</li>
                <li>5-star luxury hotel stay in Kuşadası</li>
                <li>VIP airport and hospital transfers</li>
                <li>Comprehensive pre-op and post-op care</li>
                <li>Online consultations and follow-ups</li>
              </ul>
              With a stunning vacation setting, cutting-edge medical care, and affordable pricing, we make medical tourism in Turkey a seamless and stress-free experience.
            </MDBAccordionItem>

            <MDBAccordionItem style = {{color: "black"}} collapseId={2} headerTitle={<span style = {{color:"#555"}}> What Types of Bariatric Surgery Do You Offer?</span>}>
              We specialize in gastric sleeve surgery, the most popular weight loss surgery, and offer gastric bypass surgery for revision cases.
            </MDBAccordionItem>

            <MDBAccordionItem style = {{color: "black"}} collapseId={3} headerTitle = {<span style = {{color:"#555"}}> Is the Hospital Clinically Approved for Medical Tourism? </span>}>
              Yes, our hospital is approved by the Turkish Ministry of Health for medical tourism. Additionally, Dr. Fikret Beyaz is board-certified by the **Turkish Surgical Association**, ensuring the highest standards of care.
            </MDBAccordionItem>

            <MDBAccordionItem collapseId={4} style = {{color: "black"}}  headerTitle= {<span style = {{color:"#555"}}> Which Countries Do Your Patients Come From? </span>}>
              We welcome patients from:
              <ul>
                <li>United Kingdom</li>
                <li>United States</li>
                <li>Germany</li>
                <li>Iraq</li>
                <li>The Middle East and many other countries</li>
              </ul>
            </MDBAccordionItem>

            <MDBAccordionItem collapseId={5} style = {{color: "black"}}   headerTitle= {<span style = {{color:"#555"}}> What’s Included in the Bariatric Surgery Package?</span>} >
              Our Gastric Surgery Package covers everything for a smooth and stress-free experience:
              <ul>
                <li>Gastric sleeve surgery in a fully equipped hospital</li>
                <li>5-star luxury hotel stay in Kuşadası for you and your companion</li>
                <li>Private airport and hospital transfers</li>
                <li>Comprehensive pre-op and post-op medical tests</li>
                <li>Online consultations and follow-up support</li>
              </ul>
            </MDBAccordionItem>
          </MDBAccordion>
        </MDBContainer>
</section>
<section style={{ ...sectionStyle, backgroundColor: "#f9f9f9",textAlign: "left", paddingBottom: "40px" }}>
<Box
  sx={{
    display: "flex",
    flexDirection: "column",
    alignItems: { xs: "center", md: "flex-start" }, // Centered on mobile, left-aligned on larger screens
    paddingLeft: { xs: "0px", sm: "40px", md: "80px", lg: "1.5ch" }, // Adjust padding instead of marginLeft
    width: "100%", // Ensures full-width alignment control
  }}
>
  <Typography
    variant="h3"
    sx={{
      fontWeight: "bold",
      marginBottom: "16px",
      fontSize: { xs: "1.8rem", md: "2.5rem", lg: "3rem" }, // Responsive font size
      textAlign: { xs: "center", md: "left" }, // Centered on small screens, left-aligned on larger screens
    }}
  >
    Eligibity & Safety
  </Typography>
</Box>

  <MDBContainer className="mt-5" style={{ maxWidth: "1500px", width: "100%", color: "#555" }}>
    <MDBAccordion alwaysOpen>
      
      <MDBAccordionItem collapseId={6} style = {{color: "black"}} headerTitle=  {<span style = {{color:"#555"}}> Am I Eligible for Weight Loss Surgery? </span> }>
        Bariatric surgery eligibility is determined by <strong>Body Mass Index (BMI):</strong>
        <ul>
          <li>BMI over 40</li>
          <li>BMI over 35 with health conditions such as diabetes, hypertension, or sleep apnea</li>
        </ul>
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={7} headerTitle=  {<span style = {{color:"#555"}}>How Experienced Is the Surgeon?</span>}>
        <strong>Dr. Fikret Beyaz</strong> has performed over <strong>15,000 bariatric surgeries</strong> for international patients, making him one of the most experienced weight loss surgeons in Turkey.
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={8} headerTitle= {<span style = {{color:"#555"}}>Is Gastric Sleeve Surgery Safe?</span>}>
        Yes, your safety is our top priority. We follow strict international medical standards in a fully equipped hospital with an expert surgical team.
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={9} headerTitle= {<span style = {{color:"#555"}}> What Are the Risks of Weight Loss Surgery? </span>}>
        As with any surgery, risks include:
        <ul>
          <li>Infection</li>
          <li>Bleeding</li>
          <li>Leakage</li>
        </ul>
        However, we take every precaution to ensure safe and successful outcomes.
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={10} headerTitle= {<span style = {{color:"#555"}}>How Do You Ensure Patient Safety?</span>}>
        <ul>
          <li>Comprehensive pre-op assessments</li>
          <li>24/7 medical supervision during hospital stay</li>
          <li>Strict hygiene and surgical safety protocols</li>
        </ul>
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={11} headerTitle=  {<span style = {{color:"#555"}}>What Happens If I Have Complications After Returning Home?</span>}>
        We offer free online follow-ups and provide detailed aftercare guidelines to share with your local doctor for continued care.
      </MDBAccordionItem>

    </MDBAccordion>
  </MDBContainer>

</section>
{/* Before Traveling to Turkey Section */}
<section style={{ ...sectionStyle, backgroundColor: "#f9f9f9", textAlign: "left",  paddingBottom: "40px" }}>
<Box
  sx={{
    display: "flex",
    flexDirection: "column",
    alignItems: { xs: "center", md: "flex-start" }, // Centered on mobile, left-aligned on larger screens
    paddingLeft: { xs: "0px", sm: "40px", md: "80px", lg: "1.5ch" }, // Adjust padding instead of marginLeft
    width: "100%", // Ensures full-width alignment control
  }}
>
  <Typography
    variant="h3"
    sx={{
      fontWeight: "bold",
      marginBottom: "16px",
      fontSize: { xs: "1.8rem", md: "2.5rem", lg: "3rem" }, // Responsive font size
      textAlign: { xs: "center", md: "left" }, // Centered on small screens, left-aligned on larger screens
    }}
  >
    Travelling to Turkey
  </Typography>
</Box>

  <MDBContainer className="mt-5" style={{ maxWidth: "1500px", width: "100%", color: "#555" }}>
    <MDBAccordion alwaysOpen>
      <MDBAccordionItem collapseId={12}  style = {{color: "black"}} headerTitle= {<span style = {{color:"#555"}}> Do I Need a Visa for Medical Tourism in Turkey </span>}>
        Many nationalities, including Iraqis, can quickly obtain an e-Visa online. Our team can guide you through the visa process.
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={13}  style = {{color: "black"}}  headerTitle= {<span style = {{color:"#555"}}> How Do I Book My Surgery?</span>}>
        Booking is simple:
        <ul>
          <li>Schedule a free online consultation with our medical team</li>
          <li>Confirm your eligibility and secure your spot</li>
          <li>Complete payment online before traveling</li>
        </ul>
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={14}  style = {{color: "black"}}  headerTitle= {<span style = {{color:"#555"}}>How Long Should I Stay in Turkey for Surgery?</span>}>
        We recommend staying 5-7 days to complete all pre-op tests, surgery, and initial recovery.
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={15}  style = {{color: "black"}}  headerTitle= {<span style = {{color:"#555"}}>What Should I Pack for My Medical Trip? </span>}>
        <ul>
          <li>Comfortable, loose clothing</li>
          <li>Personal documents (passport, medical records, etc.)</li>
          <li>Any prescribed medications</li>
        </ul>
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={16}  style = {{color: "black"}}  headerTitle = {<span style = {{color:"#555"}}>Can I Bring a Companion?</span>}>
        Yes, we highly recommend bringing a companion for support. We can also arrange their stay at the same 5-star hotel for a comfortable experience.
      </MDBAccordionItem>
    </MDBAccordion>
  </MDBContainer>
</section>

{/* Surgery & Hospital Stay Section */}
<section style={{ ...sectionStyle, backgroundColor: "#f9f9f9", textAlign: "left", paddingBottom: "40px" }}>
<Box
  sx={{
    display: "flex",
    flexDirection: "column",
    alignItems: { xs: "center", md: "flex-start" }, // Centered on mobile, left-aligned on larger screens
    paddingLeft: { xs: "0px", sm: "40px", md: "80px", lg: "1.5ch" }, // Adjust padding instead of marginLeft
    width: "100%", // Ensures full-width alignment control
  }}
>
  <Typography
    variant="h3"
    sx={{
      fontWeight: "bold",
      marginBottom: "16px",
      fontSize: { xs: "1.8rem", md: "2.5rem", lg: "3rem" }, // Responsive font size
      textAlign: { xs: "center", md: "left" }, // Centered on small screens, left-aligned on larger screens
    }}
  >
    Surgery & Hospital 
  </Typography>
</Box>


  <MDBContainer className="mt-5" style={{ maxWidth: "1500px", width: "100%", color: "#555" }}>
    <MDBAccordion alwaysOpen>
      <MDBAccordionItem collapseId={17}  style = {{color: "black"}}  headerTitle= {<span style = {{color:"#555"}}>What Happens on the Day of My Surgery? </span>}>
        <ul>
          <li>Pre-op medical tests and evaluation</li>
          <li>Surgery under general anesthesia (1-2 hours)</li>
          <li>Post-op hospital stay (1-2 nights) for monitoring</li>
        </ul>
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={18}  style = {{color: "black"}}  headerTitle={<span style = {{color:"#555"}}>Will I Feel Pain After Surgery?</span>}>
        Some discomfort is normal, but pain management medication is provided to ensure a smooth recovery.
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={19}  style = {{color: "black"}}  headerTitle= {<span style = {{color:"#555"}}>When Can I Start Walking After Surgery?</span>}>
        Most patients start walking within a few hours after surgery to aid recovery.
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={20}  style = {{color: "black"}} headerTitle= {<span style = {{color:"#555"}}>How Long Will I Stay in the Hospital?</span>}>
        Typically 1-2 nights, depending on your recovery progress.
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={21}  style = {{color: "black"}}  headerTitle={<span style = {{color:"#555"}}>Will I Face Language Barriers in Turkey?</span>}>
        No, our English, Arabic, and Persian-speaking coordinators and translators will assist you throughout your stay.
      </MDBAccordionItem>
    </MDBAccordion>
  </MDBContainer>
</section>

{/* Post-Surgery Aftercare & Recovery Section */}
<section style={{ ...sectionStyle, backgroundColor: "#f9f9f9", textAlign: "left", paddingBottom: "40px" }}>
<Box
  sx={{
    display: "flex",
    flexDirection: "column",
    alignItems: { xs: "center", md: "flex-start" }, // Centered on mobile, left-aligned on larger screens
    paddingLeft: { xs: "0px", sm: "40px", md: "80px", lg: "1.5ch" }, // Adjust padding instead of marginLeft
    width: "100%", // Ensures full-width alignment control
  }}
>
  <Typography
    variant="h3"
    sx={{
      fontWeight: "bold",
      marginBottom: "16px",
      fontSize: { xs: "1.8rem", md: "2.5rem", lg: "3rem" }, // Responsive font size
      textAlign: { xs: "center", md: "left" }, // Centered on small screens, left-aligned on larger screens
    }}
  >
    Aftercare & Recovery
  </Typography>
</Box>

  <MDBContainer className="mt-5" style={{ maxWidth: "1500px", width: "100%", color: "#555" }}>
    <MDBAccordion alwaysOpen>
      <MDBAccordionItem collapseId={22}  style = {{color: "black"}}  headerTitle={<span style = {{color:"#555"}}>What Should I Eat After Bariatric Surgery?</span>}>
        <ul>
          <li>First few days: Liquid diet</li>
          <li>Gradual transition to soft foods</li>
          <li>Full diet plan provided by our expert nutritionists</li>
        </ul>
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={23}  style = {{color: "black"}}  headerTitle= {<span style = {{color:"#555"}}>When Can I Return to Work?</span>}>
        Most patients resume light work within 1-2 weeks, depending on their recovery.
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={24}  style = {{color: "black"}}  headerTitle=  {<span style = {{color:"#555"}}>How Do I Follow Up After Returning Home?</span>}>
        We offer free online consultations to monitor your progress and provide medical guidance.
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={25}  style = {{color: "black"}}  headerTitle={<span style = {{color:"#555"}}>Will I Need to Take Vitamin Supplements?</span>}>
        Yes, bariatric surgery requires long-term vitamin and supplement intake, and we will guide you on what to take.
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={26}  style = {{color: "black"}} headerTitle={<span style = {{color:"#555"}}>How Much Weight Can I Expect to Lose?</span>}>
        Most patients lose 60-70% of their excess weight within the first year, depending on their diet and lifestyle.
      </MDBAccordionItem>
    </MDBAccordion>
  </MDBContainer>
  <Typography sx={{ color: "#555", fontSize: "1.rem",textAlign: "left" , marginTop: "50px" }}>
  Have more questions? Contact us today for a free consultation.
  </Typography>

</section>

    </div>
  );
};

export default FAQ;
