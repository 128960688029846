import React from "react";
import { Box, Typography } from "@mui/material";

const Second = ({ title, subtitle, text, image }) => {
  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: { xs: "column-reverse", md: "row" }, // ✅ Image first on mobile, text first on desktop
        alignItems: "center",
        width: "100%",
        height: "auto", // ✅ Adjusts dynamically
        padding: "0",
        margin: "0",
      }}
    >
      {/* Left Side - Text */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems:"flex-start",
          padding: { xs: "20px", md: "40px" }, // ✅ Smaller padding on mobile
          backgroundColor: "#f0f8ff",
          textAlign: { xs: "center", md: "left" }, // ✅ Center on mobile, left on desktop
        }}
      >
        {/* Title */}
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            color: "#195D8A",
            textAlign: { xs: "center", md: "left" },
            fontSize: { xs: "2rem", md: "2.5rem" },
            width: "100%",
          }}
        >
          {title}
        </Typography>

        {/* Subtitle */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: "medium",
            textAlign:{xs:"center", sm:"left"},
            marginTop: "40px",
            color: "#333",
            fontSize: { xs: "1.2rem", md: "1.5rem" },
            width: "100%", // ✅ Ensures alignment applies correctly
          }}
        >
          {subtitle}
        </Typography>

        {/* Underline */}
        <Box
          sx={{
            height: "3px",
            width: { xs: "100%", md: "48%" },
            textAlign: "right", // ✅ Full width on mobile, fixed on larger screens
            backgroundColor: "rgb(25, 93, 138)",
            marginLeft:{ xs: "0", md: "-6vh" },
            marginBottom: "0.5vh"            
          }}
        />

        {/* Description */}
        <Typography
          sx={{
            color: "#555",
            fontSize: { xs: "1rem", md: "1.4rem" },
            lineHeight: "1.8",
            padding: { xs: "10px", md: "40px" }, // ✅ Less padding on mobile
          }}
        >
          {text}
        </Typography>
      </Box>

      {/* Right Side - Image */}
      <Box
        component="img"
        src={image}
        alt="Doctor"
        sx={{
          flex: 1,
          width: { xs: "100%", md: "50%" }, // ✅ Full width on mobile, 50% on desktop
          height: { xs: "50vh", md: "100%" },
          objectFit: "cover",
          borderRadius: "4px",
        }}
      />
    </Box>
  );
};

export default Second;
