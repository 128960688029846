import "@fontsource/allura";
import React, { useState } from "react";
import { Typography, Box, Grid,useMediaQuery  } from "@mui/material";
import { Link } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Video from "yet-another-react-lightbox/plugins/video";
import Background from "../kusadasi/leaf.jpg";
import Gastric from "./hospital.JPG";
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Yahsi from "./yahsi.JPG"
import Header from "../global/header";
import About from "../global/about";

const Hospital = () => {
  const isMd = useMediaQuery("(min-width:768px)"); // Check if screen is at least 768px
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const slides = [
    { type: "video", sources: [{ src: "/kus.mp4", type: "video/mp4" }] },
  ];

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        backgroundColor: "#f4f4f9",
        color: "#333",
      }}
    >
      {/* Hero Section */}
      <Header
      backgroundImage={Background}
      faqImage={Gastric}
      headerText="Hospital"
    />

      {/* About Section */}
      <About
  title="About Hospital"
  text="As a medical facility, Gozde Kusadasi Hospital brings an innovative perspective to the healthcare sector by integrating the experience we have gained on our healthcare journey started in 1996 with state-of-the-art technology. 
  With our hospital in the heart of the west coast of Turkey, we continue our efforts to go one step further every day.
"
/>

      {/* Videos & Gallery Section */}
      <Box
      component="section"
      sx={{
        textAlign: "center",
        padding: "40px",
        backgroundColor: "white",
      }}
    >
      {/* Video + Gallery Thumbnails */}
      <Grid
        container
        spacing={{ xs: 2, sm: 4, md: 8 }}
        justifyContent="center"
      >
        {/* First Video - Hospital Introduction */}
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              position: "relative",
              cursor: "pointer",
              overflow: "hidden",
              borderRadius: "8px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
            }}
            onClick={() => {
              setSelectedIndex(0);
              setLightboxOpen(true);
            }}
          >
            {/* Thumbnail */}
            <Box
              component="img"
              src="/cover.png"
              alt="Video 1"
              sx={{
                width: "100%",
                height: { xs: "300px", sm: "400px" },
                objectFit: "cover",
                borderRadius: "8px",
                transition: "opacity 0.3s ease-in-out",
                "&:hover": { opacity: 0.7 },
              }}
            />

            {/* Play Icon */}
            <PlayCircleOutlinedIcon
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontSize: { xs: "60px", sm: "80px", md: "100px" },
                color: "black",
                opacity: 0.6,
                transition: "opacity 0.3s ease-in-out",
              }}
            />
          </Box>
          {/* Title */}
          <Typography sx={{ marginTop: "10px", fontWeight: "bold", fontSize: "1.5rem" }}>
            Discover Our Hospital
          </Typography>
        </Grid>

        {/* Second Image - Hospital Gallery */}
        <Grid item xs={12} sm={6}>
        <Link to="/gallery" style={{ textDecoration: "none" }}>
  <Box
    sx={{
      position: "relative",
      cursor: "pointer",
      overflow: "hidden",
      borderRadius: "8px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    }}
  >
    {/* Thumbnail */}
    <Box
      component="img"
      src={Yahsi}
      alt="Gallery"
      sx={{
        width: "100%",
        height: { xs: "300px", sm: "400px" },
        objectFit: "cover",
        borderRadius: "8px",
        transition: "opacity 0.3s ease-in-out",
        "&:hover": { opacity: 0.7 },
      }}
    />
  </Box>
  </Link>


  {/* Title */}
  <Typography sx={{ marginTop: "10px", fontWeight: "bold", fontSize: "1.5rem" }}>
    Go to Hospital Gallery
  </Typography>

    </Grid>
      </Grid>

      {/* Info Text with Link */}
      <Typography
        sx={{
          fontWeight: "bold",
          marginTop: "40px",
          color: "#555",
          textAlign: "center",
          fontSize: { xs: "1.0rem", sm: "1.2rem" },
        }}
      >
        If you want to explore the Gozde Kusadasi Hospital more,{" "}
        <a
          href="https://www.gozdeinternationalhospitals.com/gozde-kusadasi-hospitals/"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#007BFF",
            textDecoration: "none",
          }}
        >
          click here
        </a>{" "}
        to see the hospital's website.
      </Typography>
    </Box>      
      {/* Lightbox for Videos */}
      <Lightbox 
  open={lightboxOpen} 
  close={() => setLightboxOpen(false)} 
  slides={slides.map((slide, idx) => (
   
    { ...slide,
       autoPlay: true,
       width: isMd ? 1200 : 600,  // 1200px for md+, 600px for xs
       height: isMd ? 700 : 1000, // Adjust height accordingly
   

       }  // Increase size


  ))} 
  plugins={[Video]} 
  index={selectedIndex} 
  styles={{ container: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }}

/>

    </div>
  );
};

export default Hospital;