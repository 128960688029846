import React from "react";
import { Box, Typography } from "@mui/material";
import Background from  "../../kusadasi/leaf.jpg"
import Gastric from "./background.png";
import Overview from "./wife.jpg"
import Hospital from "../gastric_sleeve/hospital.jpg";
import Doctor from "./bariatric.png"
import "@fontsource/allura";
import BMICalculator from "./bmi";
import Header from "../../global/header";
import About from "../../global/about"
import First from "../../kusadasi/first"
import Second from "../../global/second";
const Bariatric = () => {

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        backgroundColor: "#f4f4f9",
        color: "#333",
      }}
    >
      {/* Hero Section */}
      <Header  backgroundImage={Background}
            faqImage={Gastric}
            headerText="Bariatric Surgery"
        />
      <About
  title="What is Bariatric Surgery?"
  text="Bariatric surgery, also known as metabolic or weight loss surgery, is a highly effective solution for combating obesity and improving overall health.
  This type of surgery works by altering the digestive system to promote long-term weight loss, improve hormonal balance,
  and address obesity-related health complications. Bariatric procedures, such as gastric sleeve and gastric bypass, reduce stomach size, limit nutrient absorption, and adjust hormones that control hunger and fullness. 
  These changes help patients achieve and maintain a lower weight set point, leading to improved health and enhanced quality of life. However,
  for the best results, bariatric surgery must be complemented by a healthy diet and regular exercise."
/>

 

      {/* About Section */}
      <First
      imageSrc={Overview}
      title="What is Obesity?"
      text="Obesity is a serious medical condition characterized by abnormal fat accumulation that poses a health risk. According to the World Health Organization (WHO), a body mass index (BMI) over 25 is considered overweight,
      and a BMI over 30 is considered obese. In 2019, obesity-related diseases caused over 5 million deaths globally.
      BMI is calculated by dividing your weight by your height  squared, then multiplying by 703/100. 
      A BMI of 30 or higher is classified as obese, with severe obesity starting at 40 and super-obesity at 50.
      BMI is calculated by taking the weight in pounds/kilogram, dividing that number by your height in inches/centimeters squared, 
      and multiplying that by 703/100. A BMI of 30 or higher is considered obese. A BMI over 40 is considered severely obese. 
      Even though BMI is not enough to determine obesity alone ( Some people, including amateur athletes, may have a higher BMI because muscles are more dense than fat. ), 
      it is a good starting point to consider health issues. "
    />
            
      <section style={{ backgroundColor: "white", padding: "40px 20px" }}>
  <Typography variant="h4" sx={{ fontWeight: "bold", marginBottom: "16px", color: "#555" }}>
    The Global Impact of Obesity
  </Typography>
  <Typography sx={{ color: "#555", fontSize: "1rem", lineHeight: "1.8", marginBottom: "20px" }}>
    According to WHO, in 2022, 2.5 billion adults aged 18 years and older were overweight, 
    including over 890 million adults who were living with obesity. 
    This corresponds to 43% of adults aged 18 years and over (43% of men and 44% of women) who were overweight; an increase from 1990,
     when 25% of adults aged 18 years and over were overweight. 
     Prevalence of overweight varied by region, from 31% in the WHO South-East Asia Region 
     and the African Region to 67% in the Region of the Americas. Obesity comes with several health problems which are listed above:
  </Typography>
  <Typography sx={{ color: "#555", fontSize: "1rem", lineHeight: "1.8" }}>

    <span style={{ color: "rgb(65, 148, 163)" }}>●</span> Weakens the immune response and increases the risk of infections.<br/>
    <span style={{ color: "rgb(65, 148, 163)" }}>●</span> Increases risk of insulin resistance and type 2 diabetes    <br/>
    <span style={{ color: "rgb(65, 148, 163)" }}>●</span> Raises the risk of high blood pressure and heart diseases.<br/>
    <span style={{ color: "rgb(65, 148, 163)" }}>●</span> Strains joints, leading to osteoarthritis    <br/>
    <span style={{ color: "rgb(65, 148, 163)" }}>●</span> Contributes to sleep apnea and other breathing issues    <br/>
    <span style={{ color: "rgb(65, 148, 163)" }}>●</span> Causes hormonal imbalances, leading to infertility.<br/>
    <span style={{ color: "rgb(65, 148, 163)" }}>●</span> Affects mental health, contributing to depression and low self-esteem<br/>
    <span style={{ color: "rgb(65, 148, 163)" }}>●</span> Maintaining a healthy diet, regular exercise, and positive lifestyle changes can strengthen the immune system.
  </Typography>
</section>



  <BMICalculator />
  <Second
      title="Bariatric Surgery"
      subtitle="How to Help"
      text={`
        Bariatric surgery directly impacts the digestive system and hormones to promote weight loss and improve metabolic function.
         It is an effective solution for those struggling with obesity, offering long-term weight loss benefits that traditional diets and exercise can’t always achieve. Popular surgeries like gastric sleeve and gastric bypass reduce stomach size and induce hormonal changes to regulate hunger,
          while boosting insulin sensitivity. These surgeries not only support weight loss but can also reduce or eliminate obesity-related conditions such as diabetes, hypertension, and sleep apnea.
           Bariatric surgery is a transformative treatment for long-term weight management, improving both physical and mental health.
`}
      image={Doctor}
    />
      <section style={{ backgroundImage: `url(${Hospital})`, backgroundSize: "cover", backgroundPosition: "center", padding: "40px 20px" }}>
  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "30px" }}>

    {/* First Row - Gastric Sleeve and Gastric Bypass */}
    <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: "60px" }}>
      
      {/* Gastric Sleeve */}
      <Box sx={{ maxWidth: "400px", height: "120%", padding: "20px", borderRadius: "12px", border: "white", backgroundColor: "white" }}>
        <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: "12px", color: "#555",textAlign:"center" }}>
        Gastric Sleeve Surgery
        </Typography>
        <Typography sx={{ color: "#555", fontSize: "1rem", lineHeight: "1.8",textAlign:{xs:"center",md:"left"}  }}>
        Gastric sleeve surgery, or sleeve gastrectomy, is one of the most popular bariatric surgeries. It reduces the stomach size by about 80%, making patients feel fuller faster and helping them maintain long-term weight loss. This procedure is less invasive than gastric bypass and offers faster recovery times, requiring fewer vitamin supplements. 
        
        </Typography>
      </Box>

      {/* Gastric Bypass */}
      <Box sx={{ maxWidth: "400px", height: "120%", padding: "20px", borderRadius: "12px", border: "white", backgroundColor: "white" }}>
        <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: "12px", color: "#555",textAlign:"center" }}>
        Gastric Bypass Surgery
        </Typography>
        <Typography sx={{ color: "#555", fontSize: "1rem", lineHeight: "1.8",textAlign:{xs:"center",md:"left"} }}>
        Gastric sleeve surgery, or sleeve gastrectomy, is one of the most popular bariatric surgeries. It reduces the stomach size by about 80%, making patients feel fuller faster and helping them maintain long-term weight loss. This procedure is less invasive than gastric bypass and offers faster recovery times, requiring fewer vitamin supplements. 

        </Typography>
      </Box>
    </Box>

    {/* Third Row - Key Differences */}
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      {/* Key Differences */}
      <Box sx={{ width: "250%", padding: "20px", borderRadius: "12px", border: "white", backgroundColor: "white" }}>
        <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: "12px", color: "#555",textAlign:{xs:"center",md:"left"} }}>
          Key Differences: Gastric Sleeve vs Gastric Bypass
        </Typography>
        <Typography sx={{ color: "#555", fontSize: "1rem", lineHeight: "1.8" }}>
          <strong>Gastric Sleeve Surgery:</strong><br />
          - Less complex than gastric bypass<br />
          - Lower chance of causing dumping syndrome<br />
          - Requires fewer vitamin supplements<br />
          - Can be changed to a gastric bypass<br />
          - Usually results in less weight loss compared to gastric bypass<br /><br />

          <strong>Gastric Bypass Surgery:</strong><br />
          - More complex with higher risk of complications<br />
          - Higher chance of dumping syndrome<br />
          - Requires more vitamin supplements<br />
          - Typically results in greater weight loss
        </Typography>
      </Box>
    </Box>

  </Box>
</section>

<Box 
      component="section" 
      sx={{ width: "100%", height: "100%", margin: "0", padding: "0" }}
    >
      <Box sx={{ textAlign: "center", padding: { xs: "20px", md: "40px 0" } }}>
        
        {/* Who can be a candidate for Surgery? */}
        <Typography 
          variant="h3"
          sx={{
            fontSize: { xs: "1.8rem", md: "2.5rem" },
            color: "#555",
            fontWeight: "bold",
            marginBottom: { xs: "20px", md: "20px" },
          }}
        >
          Who can be a candidate for Surgery?
        </Typography>

        <Typography 
          sx={{
            fontSize: { xs: "1rem", md: "1.2rem" },
            color: "#555",
            maxWidth: { xs: "90%", md: "800px" },
            margin: "0 auto",
            lineHeight: "1.6",
            marginBottom: { xs: "40px", md: "80px" },
          }}
        >
          Bariatric surgery is ideal for individuals struggling with obesity-related health issues like type 2 diabetes, high blood pressure, osteoarthritis, and sleep apnea.
          It is most effective for those who are 100+ pounds overweight or have a BMI of 40 or higher. 
          Individuals with a BMI between 30-39.9 can qualify if they have serious obesity-related conditions. 
          Surgery candidates should be unable to lose weight through diet and exercise alone and must be free of drug or alcohol dependence.
        </Typography>

        {/* Ideal Age Range */}
        <Typography 
          variant="h3"
          sx={{
            fontSize: { xs: "1.8rem", md: "2.5rem" },
            color: "#555",
            fontWeight: "bold",
            marginBottom: { xs: "20px", md: "20px" },
          }}
        >
          Ideal Age Range for Bariatric Surgery
        </Typography>

        <Typography 
          sx={{
            fontSize: { xs: "1rem", md: "1.2rem" },
            color: "#555",
            maxWidth: { xs: "90%", md: "800px" },
            margin: "0 auto",
            lineHeight: "1.6",
            marginBottom: { xs: "40px", md: "80px" },
          }}
        >
          Bariatric surgery is typically recommended for adults aged 18-65. However, adolescents may also qualify if surgery is necessary for their health.
          Patients must be ready to commit to long-term lifestyle changes, including healthy eating habits and exercise, to ensure the best results. 
          Before the procedure, medical teams carefully evaluate patients to confirm they meet all the requirements for any surgery.
        </Typography>

        {/* Other Procedures */}
        <Typography 
          variant="h3"
          sx={{
            fontSize: { xs: "1.8rem", md: "2.5rem" },
            color: "#555",
            fontWeight: "bold",
            marginBottom: { xs: "20px", md: "20px" },
          }}
        >
          Other Procedures
        </Typography>

        <Typography 
          sx={{
            fontSize: { xs: "1rem", md: "1.2rem" },
            color: "#555",
            maxWidth: { xs: "90%", md: "800px" },
            margin: "0 auto",
            lineHeight: "1.6",
            marginBottom: { xs: "20px", md: "20px" },
          }}
        >
          In addition to gastric sleeve and gastric bypass, other weight loss surgeries include transit bipartition, gastric Botox, revision surgery, mini bypass surgery, and reflux surgery. 
          These procedures offer different options for weight loss and improving health. You can contact us to learn more details.
        </Typography>

      </Box>
    </Box>
                
    </div>
  );
};

export default Bariatric;
