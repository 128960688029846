
import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";

export default function () {
  const styles = {
    timeline: {
      borderLeft: "3px solid rgb(148, 179, 243)",
      borderBottomRightRadius: "4px",
      borderTopRightRadius: "4px",
      background: "rgba(177, 99, 163, 0.09)",
      margin: "auto auto",
      position: "relative",
      padding: "50px",
      listStyle: "none",
      textAlign: "left",
      maxWidth: "min(90%, 500px)",
    },

    event: {
      borderBottom: "1px dashed #000",
      paddingBottom: "25px",
      marginBottom: "25px",
      position: "relative",
    },
    lastEvent: {
      paddingBottom: "0",
      marginBottom: "0",
      borderBottom: "none",
    },
    eventBefore: {
      position: "absolute",
      left: "-207px",
      textAlign: "right",
      fontWeight: "100",
      fontSize: "clamp(0.8rem, 1vw, 1rem)", // ✅ Auto-scales based on screen width
      minWidth: "10px",
    },
    eventAfter: {
      boxShadow: "0 0 0 3px rgb(163, 227, 255)",
      position: "absolute",
      left: "-55.8px",
      background: "#fff",
      borderRadius: "50%",
      height: "9px",
      width: "9px",
      content: '""',
      top: "5px",
    },
    responsive: {
      maxWidth: "98%",
      padding: "25px",
    },
  };

  return (
    <MDBContainer className="py-5">
      <MDBRow>
        <MDBCol md="12">
          <div id="content">
            <ul style={styles.timeline}>
              <li style={styles.event}>
                <h4 className="mb-3">Surgery Day</h4>
                <p>
                  Patients wake up in a recovery room under close supervision, ensuring your comfort and safety.
                </p>
                <span style={styles.eventAfter}></span>
              </li>

              <li style={styles.event}>
                <h4 className="mb-3">Days 2-3</h4>
                <p>
                  Patients stay in the hospital for observation while starting their liquid diet for successful recovery.
                </p>
                <span style={styles.eventAfter}></span>
              </li>

              <li style={styles.event}>
                <h4 className="mb-3">Week 1</h4>
                <p>
                  Patients transition to a clear liquid diet. Mild discomfort is normal, and prescribed painkillers will help. Light walking is encouraged.
                </p>
                <span style={styles.eventAfter}></span>
              </li>

              <li style={styles.event}>
                <h4 className="mb-3">Week 2</h4>
                <p>
                  Patients slowly introduce pureed foods. Staying hydrated and focusing on protein supports healing. Avoid heavy lifting.
                </p>
                <span style={styles.eventAfter}></span>
              </li>

              <li style={styles.event}>
                <h4 className="mb-3">Weeks 3-4</h4>
                <p>
                  Soft foods are added. Gradually increase activities. Attend follow-up appointments and consult your doctor in person.
                </p>
                <span style={styles.eventAfter}></span>
              </li>

              <li style={styles.event}>
                <h4 className="mb-3">Month 2</h4>
                <p>
                  Transition to solid foods with a focus on protein. With doctor approval, return to normal activities and exercise.
                </p>
                <span style={styles.eventAfter}></span>
              </li>

              <li style={styles.event}>
                <h4 className="mb-3">Months 3-6</h4>
                <p>
                  Follow your diet and exercise routine. Email check-ins will monitor your health and progress during this phase.
                </p>
                <span style={styles.eventAfter}></span>
              </li>

              <li style={{ ...styles.event, ...styles.lastEvent }}>
                <h4 className="mb-3">Month 12</h4>
                <p>
                  Significant weight loss should be visible after one year. Attend regular check-ups and continue healthy habits.
                </p>
                <span style={styles.eventAfter}></span>
              </li>
            </ul>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}


