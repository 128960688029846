import React, {useState} from "react";
import { Box, Typography, Grid, Divider } from "@mui/material";
import "yet-another-react-lightbox/styles.css";
import Background from "./leaf.jpg";
import Kusadasi from "./kusadasi-giris.jpg";
import Restaurant from "./restaurant.jpg"
import Health from "./health.png"
import History from "./history.JPG"
import Beach from "./beach.jpg"
import "@fontsource/allura";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import Sirince from "./Street.jpeg"
import Efes2 from "./efes2.jpg"
import Ladies from "./ladies.jpg"
import Pamukkale from "./pamukkale.jpg"
import Header from "../global/header";
import About from "../global/about";
import First from "./first"
import KusadasiSection from "../homepage/kusadasi";
const activities = [
  {
    title: "Explore Ephesus",
    description: "Ephesus was an ancient Roman and Greek city in Kusadasi. Ephesus was a great example of a Roman port city with a sea channel and a harbor. The ancient city had notable Roman buildings, like the Library of Celsus, the Great Theatre, and The Temple of Artemis, one of the Seven Wonders of the World which was famous and brought visitors from many places, but only a few pieces remain today. Since the 5th century, the House of the Virgin Mary, a small chapel 7 km from Ephesus, became essential for Christian visitors. ",
    image: Efes2, // Replace with actual image URL
  },
  {
    title: "Relax at Ladies Beach",
    description: "Ladies Beach in Kusadasi is a stunning beach just minutes from downtown! A heaven for sun-seekers, adventure lovers, and water sports fans, this famous beach offers golden sands and crystal-clear waters. Whether you want to relax under the sun, take a refreshing swim, or enjoy beach sports or activities with friends, Ladies Beach is the perfect destination for locals and visitors worldwide. Don't miss out—experience the beauty of Kusadasi today.",
    image: Ladies,
  },
  {
    title: "Discover Charm of Şirince",
    description: "Şirince is a beautiful hillside village near Ephesus, famous for its fruit wines, fresh grapes, and stunning views. Walk through its iconic stone streets, enjoy local wine or grapes, and relax in its peaceful nature. In 2012, many people visited Şirince because of the Mayan Calendar prophecy, believing it was a unique, safe place. Whether you love wine, history, or quiet getaways, Şirince is the perfect spot!",
    image:  Sirince,
  },
  {
    title: "The Cotton Paradise: Pamukkale",
    description: "Pamukkale, also called the “Cotton Castle,” is a stunning natural site with white mineral terraces and warm thermal pools, located about 185 km (115 miles) from Kusadasi. People have visited its healing waters for centuries to relax and enjoy the beauty. Walk barefoot on the soft, white travertines, swim in ancient thermal baths, and explore the ruins of Hierapolis, an old Roman city. Whether you love nature, history, or relaxation, Pamukkale is a must-see! ",
    image: Pamukkale ,
  },
];

const KusadasiPage = () => {
  const [hoverIndex, setHoverIndex] = useState(null); // Track hover state
  const navigate = useNavigate();
  
  
  const sectionStyle = {
    height: "100vh", // Full viewport height
    width: "100%", // Full width
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    textAlign: "center",
  };

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        backgroundColor: "#f4f4f9",
        color: "#333",
      }}
    >
      {/* Hero Section */}
      <Header
      backgroundImage={Background}
      faqImage={Kusadasi}
      headerText="Discover Kusadasi"
    />

<About
  title="About Kusadasi"
  text="Kuşadası is a lively town on the Aegean coast of Turkey, in Aydın Province. 
  It is about 95 kilometers south of İzmir, the third-largest city in Turkey and one of the most popular tourist destinations. 
  The town is close to famous places like Ephesus, a well-preserved ancient city from Ionia. Kuşadası is known for its beautiful beaches, 
  a busy cruise port, and warm weather, making it a popular spot for tourists to enjoy history, nature, and local life all together. 
  It is also growing as a destination for health tourism with modern facilities, such as wellness retreats, obesity centers, hair transplants, 
  and dental services, attracting visitors seeking relaxation and medical care simultaneously.

"
/>

<First
      imageSrc={Health}
      title="Health Tourism"
      text="Kuşadası has an increasing trend as a leading destination for health tourism, thanks to high-quality medical care combined with the town's natural beauty and relaxing atmosphere. Especially weight loss treatments are particularly popular due to the availability of advanced procedures at affordable prices. The clinics and hospitals in Kuşadası have the latest technology with experienced medical professionals specializing in weight loss. Some popular treatments are gastric sleeve surgery and stomach botox, which help patients achieve long-term and permanent weight loss.
      In addition to medical care, Kuşadası's calm coastal environment provides an ideal environment for recovery and relaxation. Patients can heal and discover the town while enjoying beautiful beaches, wellness retreats, and spa experiences. This combination of expert medical care and a heavenly atmosphere makes Kuşadası an attractive destination for patients desiring weight loss while enjoying their vacation."
    />

 
<section style={{ textAlign: "center", padding: "40px", backgroundColor: "white", height: "20vh" }}>
   <Typography
    variant="h3"
    sx={{
      fontWeight: "bold",
      textAlign: "center",
      marginBottom: "80px",
      color: "#333",
      fontSize: "1.8rem",
    }}
  >
    Things to Do in Kusadasi
  </Typography>
  </section>
  <KusadasiSection />
  <section style={{  height: "5vh", backgroundColor:"white" }}>
  </section>


      <section style={{ ...sectionStyle, backgroundColor: "white", height: "auto" }}>
      <Box sx={{ padding: {xs:"32px", md:"32px"}}}>
  {/* Title */}

  {/* Subtitle */}
    <Grid container spacing={4}>
          {activities.map((activity, index) => (
            <Box key={index} sx={{ width: "100%",marginLeft:"4ch" }}>

            <Grid
              key={index}
              container
              spacing={2}
              alignItems="center"
              direction={index % 2 === 0 ? "row" : "row-reverse"} // Alternating layout
            >
              {/* Image Section */}
              <Grid item xs={12} md={6}>
                <Box
                  onClick={() => navigate(activity.link)} // Navigate on click
                  onMouseOver={() => setHoverIndex(index)} // Track hover index
                  onMouseOut={() => setHoverIndex(null)} // Reset hover on leave
                  sx={{
                    height: "400px",
                    backgroundImage: `url(${activity.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
                    cursor: "pointer", // Show pointer on hover
                    transition: "opacity 0.5s ease", // Smooth transition
                    opacity: hoverIndex === index ? 0.7 : 1, // Fade out effect
                  }}
                />
              </Grid>


              {/* Text Section */}
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", color: "#555", marginBottom: "8px"}}
                >
                  {activity.title}
                </Typography>
                <Typography sx={{ color: "#555", fontSize: "1.2rem", lineHeight: "1.6",textAlign: {sm: "center", md: "left"} }}>
                  {activity.description}
                </Typography>
              </Grid>
            </Grid>
                  {index < activities.length  && (
                    <Divider sx={{ width: "100%", margin: "40px auto", backgroundColor: "rgb(26, 102, 168)", height: "2px" }} />
                  )}
          </Box>

          
          ))}
        </Grid>
    <br />
    <br />
    <Typography
    sx={{
      textAlign: "center",
      marginBottom: "32px",
      marginTop: "-50px",

      color: "#555",
      fontSize: "1.1rem",
    }}
  >
    Discover Kuşadası by looking at its outstanding restaurants, vibrant nightlife, historical sites, and breathtaking beaches.
    <br />

    <b>Click to see more suggestions.</b>
  </Typography>

  {/* Image Grid */}
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr", md: "1fr 1fr 1fr" }, // Added 3 columns for medium screens
      gap: "20px",
    }}
  >
    {/* Restaurants */}
    <Box
      component="a"
      href="https://www.tripadvisor.ca/Restaurants-g297972-Kusadasi_Turkish_Aegean_Coast.html" // Replace with actual link
      sx={{
        position: "relative",
        overflow: "hidden",
        borderRadius: "8px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        component="img"
        src={Restaurant} // Replace with actual image
        alt="Kuşadası Restaurants"
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(1.20)",
          },
        }}
      />
      <Typography
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "#fff",
          padding: "12px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Gastronomy
      </Typography>
    </Box>

   

    {/* Historical Places */}
    <Box
      component="a"
      href="https://www.viator.com/en-CA/Kusadasi/d582" // Replace with actual link
      sx={{
        position: "relative",
        overflow: "hidden",
        borderRadius: "8px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        component="img"
        src={History} // Replace with actual image
        alt="Kuşadası Historical Places"
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(1.20)",
          },
        }}
      />
      <Typography
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "#fff",
          padding: "12px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
       Historical Places
      </Typography>
    </Box>

    {/* Beaches */}
    <Box
      component="a"
      href="https://www.tripadvisor.ca/Attractions-g297972-Activities-c61-t52-Kusadasi_Turkish_Aegean_Coast.html" // Replace with actual link
      sx={{
        position: "relative",
        overflow: "hidden",
        borderRadius: "8px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        component="img"
        src={Beach} // Replace with actual image
        alt="Kuşadası Beaches"
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(1.20)",
          },
        }}
      />
      <Typography
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "#fff",
          padding: "12px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Beaches
      </Typography>
    </Box>
  </Box>
</Box>

      </section>
    </div>
  );
};

export default KusadasiPage;
