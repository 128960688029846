import React from "react";
import {Link} from "react-router-dom";
import {
    Card,
    CardContent,
    Tabs,
    Tab,
    Typography,
    Box,
} from "@mui/material";
import { List, ListItem } from "@mui/material";
import Background from "../kusadasi/leaf.jpg";
import Gastric from "./price.JPG";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Header from "../global/header";
import About from "../global/about";
  
  const pricingData = [
    {
      category: "Gastric Sleeve",
      packages: [
        {
          name: "Bariatric Package",
          price: "Starting From 4200$" ,
          details: [
            "Comprehensive Blood Tests",
            "Chest X-Ray",
            "Full Abdominal Ultrasound (USG)",
            "Electrocardiogram (ECG)",
            "Respiratory Function Test",
            "Specialist Consultations",
            "Endoscopy",
            "Post-Surgery Full Abdominal CT Scan",
            "One-night hospital stay & one-night hotel stay",
          ],
        },
      ],
    },
    {
      category: "All-Inclusive",
      packages: [
        {
          name: "Bariatric All-Inclusive",
          price: "Starting From 5200$",
          details: [
            "Includes everything in the standard package",
            "Three-night stay at a 5-star hotel",
            "Cultural Tours",
            "E-SIM Card for mobile data",
          ],
        },
      ],
    },
    {
      category: "Check-Up",
      packages: [
        {
          name: "Check-Up Package",
          price: "Starting From 1300$",
          details: [
            "Ferritin, Fasting Insulin, Folic Acid",
            "Magnesium, Phosphorus, Potassium",
            "Complete Urinalysis, Cholesterol, Blood Group",
            "Iron, Vitamin B12, 25-OH Vitamin D3",
            "Anti-HIV ELISA, Anti-HCV, AFP",
            "And more comprehensive tests",
          ],
        },
      ],
    },
    {
      category: "Hair Transplant",
      packages: [
        {
          name: "FUE Hair Transplantation",
          price: "Starting From 1650$",
          details: [
            "Doctor Consultation & Blood Tests",
            "FUE Hair Transplant Procedure (4-7 hours)",
            "Post-Operation Care (Medications, Shampoo, Creams)",
            "Fit-to-Fly Certificate",
            "Minimum 3-day stay recommendation",
          ],
        },
      ],
    },
    {
      category: "Dental Care",
      packages: [
        {
          name: "Personalized Tooth Care",
          price: "Contact for Pricing",
          details: [
            "Free Consultation & Digital Dental X-Ray",
            "Teeth Whitening, Implants, Veneers, Crowns",
            "Post-Treatment Care & Follow-up Consultation",
          ],
        },
      ],
    },
  ];
    
const PricingPage = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);



  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        backgroundColor: "#f4f4f9",
        color: "#333",
      }}
    >
      {/* Hero Section */}
      <Header  backgroundImage={Background}
            faqImage={Gastric}
            headerText="Packages"
        />
      <About
  title="About Packages"
  text="We present various medical tourism packages for you and your companion.
  Our packages offer different accommodation options with potential cultural tours to our beautiful place, Kusadasi. 
  A comprehensive pre-operative and post-operative care plan is always in service, ensuring your safety and comfort. We also want to help your companion with check-ups, dentistry, and hair transplant options, which are among the highest quality services around the globe at a reasonable price. 
  Let's come to Kusadasi and change your life."
/>


     
<section
      style={{
        background: "linear-gradient(90deg,rgb(4, 35, 50), #E1F5FE),rgb(4, 35, 50)", // ✅ Baby blue gradient
        padding: "50px 0",
      }}
    >
        <Box sx={{ maxWidth: "800px", margin: "auto", padding: 3 }}>
          <Typography variant="h4" align="center" gutterBottom color="white ">
            Pricing Packages
          </Typography>
          <Tabs
          value={selectedTab}
          onChange={(e, newValue) => setSelectedTab(newValue)}
          centered
          variant="scrollable"  // ✅ Enables horizontal scrolling
          scrollButtons="auto"  // ✅ Adds scroll buttons when needed
          sx={{
            maxWidth: "100%",   // ✅ Ensures it doesn't overflow on mobile
            overflowX: "auto",  // ✅ Allows scrolling if needed
            whiteSpace: "nowrap",
            "& .MuiTabs-indicator": {
      backgroundColor: "#FF9800", // ✅ Change the underline color (default is blue)
    }, // ✅ Prevents text wrapping
          }}
        >
          {pricingData.map((category, index) => (
            <Tab key={index} label={category.category} 
            sx={{fontSize:{xs:"1.2rem",md:"1rem"}, color:"white",
              "&.Mui-selected": {
                color: "#FF9800", // ✅ Change selected tab text color
              }, }}
            />
          ))}
        </Tabs>

        
          <Box sx={{ marginTop: 3 ,justifyContent: "center",display: "flex"}}>
      {pricingData[selectedTab].packages.map((pkg, index) => (
        <Card key={index} sx={{ marginBottom: 2, padding: 1,            
            backgroundColor: "#f5f5f5",  // Change this to any color you want
            borderRadius: "12px",  // Optional: for rounded corners
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            minWidth:"80%", // Optional: adds shadow effect
            //maxHeight:"600px"
 }}>
          <CardContent>
            {/* Package Name */}
            <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333" }}>
              {pkg.name}
            </Typography>

            {/* Price */}
            <Typography color="text.secondary" gutterBottom sx={{ fontSize: "18px", fontWeight: "600" }}>
              {pkg.price}
            </Typography>

            {/* Styled List with Custom Bullet Points */}
            <List sx={{ paddingLeft: 2 }}>
              {pkg.details.map((detail, i) => (
                <ListItem key={i} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <CheckCircleIcon style={{ color: "#FF9800" }} />
                  <Typography sx={{ fontSize: {xs:"14px", md:"18px"}, fontWeight: "500", color: "#555" }}>
                    {detail}
                  </Typography>
                </ListItem>
              ))}
            </List>

            {/* Contact Us Button */}
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 16 }}>
                      <Link
                        to={`/contactus`} // Link to the next page
                        style={{
                          marginTop: "37px",
                          background: "linear-gradient(90deg, rgb(66, 73, 207),rgb(70, 78, 230)",
                          color: "white",
                          border: "none",
                          padding: "5px 10px",
                          borderRadius: "6px",
                          fontSize: "1.2rem",
                          cursor: "pointer",
                          transition: "all 0.1s ease",
                          textDecoration: "none"
                        }}
                        onMouseOver={(e) => {
                          e.target.style.background = "linear-gradient(90deg, rgb(90, 100, 240), rgb(217, 212, 228))"; // Lighter gradient
                          e.target.style.color = "rgba(4, 3, 57, 0.84)"; // Change text color to black
                          e.target.style.transform = "scale(1)"; // Slight scaling effect
                        }}
                        onMouseOut={(e) => {
                          e.target.style.background = "linear-gradient(90deg, rgb(66, 73, 207),rgb(70, 78, 230)"; // Original gradient
                          e.target.style.color = "white"; // Reset text color to white
                          e.target.style.transform = "scale(1)"; // Reset scaling
                        }}
                      >
                        Contact Us
                      </Link>
              

      </div>


          </CardContent>
        </Card>
      ))}
    </Box>

        </Box>
      </section>

   
    </div>
  );
};

export default PricingPage;

/*
*/