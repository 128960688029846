import React, { useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Video from "yet-another-react-lightbox/plugins/video";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";

import Kus from "../kusadasi/kusadasi.jpg";
import Efes from "../kusadasi/efes.jpg";
import Sirince from "../kusadasi/Street.jpeg";
import { useMediaQuery } from "@mui/material";


const KusadasiSection = () => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const isMd = useMediaQuery("(min-width:768px)"); // Check if screen is at least 768px


  // Define the videos (same ones you are using)
  const slides = [
    { type: "video", sources: [{ src: "/kusadasi.mp4", type: "video/mp4" }] },
    { type: "video", sources: [{ src: "/efes.mp4", type: "video/mp4" }] },
    { type: "video", sources: [{ src: "/sirince.mp4", type: "video/mp4" }] },
  ];

  return (
    <section style={{ textAlign: "center",backgroundColor: "white"}}>
      {/* Section Title */}

      {/* Video Thumbnails - Keep same design */}
      <Grid container spacing={4} justifyContent="center">
        {slides.map((video, index) => (
          <Grid item key={index}>
            <Box
              sx={{
                position: "relative",
                cursor: "pointer",
                overflow: "hidden",
                borderRadius: "8px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              }}
              onClick={() => {
                setSelectedIndex(index);
                setLightboxOpen(true);
              }}
            >
              {/* Video Thumbnail */}
              <img
                src={[Kus, Efes, Sirince][index]} // Uses same images
                alt={`Video ${index + 1}`}
                onMouseEnter={(e) => (e.target.style.opacity = "0.7")}
                onMouseLeave={(e) => (e.target.style.opacity = "1")}
                style={{
                  width: "300px",
                  height: "400px",
                  objectFit: "cover",
                  borderRadius: "8px",
                  transition: "opacity 0.3s ease-in-out",
                }}
              />

              {/* Play Icon */}
              <PlayCircleOutlinedIcon
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "100px",
                  color: "black",
                  opacity: "0.6",
                  transition: "opacity 0.3s ease-in-out",
                }}
              />
            </Box>

            {/* Video Title */}
            <Typography sx={{ marginTop: "3vh", fontWeight: "bold", fontSize: "1.8rem",  }}>
              {["Kusadasi", "Ephesus", "Sirince"][index]}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {/* Lightbox - Fullscreen Video Player */}
      <Lightbox
        open={lightboxOpen}
        close={() => setLightboxOpen(false)}
        slides={slides.map((slide) => ({
          ...slide,
          autoPlay: true,
          width: isMd ? 1200 : 600,  // 1200px for md+, 600px for xs
          height: isMd ? 700 : 1200, // Adjust height accordingly
      
     
        }))}
        plugins={[Video]}
        index={selectedIndex}
        styles={{ container: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }}
      />
    </section>
  );
};

export default KusadasiSection;
