
import React from "react";
import { Box, Typography, IconButton, Button} from "@mui/material";
import Kitten from "./kitten.jpg";
import Logo from "./transparent.png"
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useLocation, Link} from "react-router-dom";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";

const Cat = () => {
  const openGoogleMaps = () => {
    window.open(
      "https://www.google.com/maps/place/Türkmen,+Rıza+Saraç+Cd.,+09400+Kuşadası/Aydın",
      "_blank"
    );
  };

  const location = useLocation(); // Get the current route

  const navItems = [
    { label: "About", link: "/" },
    { label: "Procedures", link: "/procedures/bariatricsurgery" },
    { label: "Gallery", link: "/gallery" },
    { label: "Kusadasi", link: "/kusadasi" },
    { label: "Financing", link: "/financing" },
    { label: "FAQ", link: "/faq" },
  ];

  return (
    <>
      {/* First Section - Conditionally Rendered */}
      {location.pathname !== "/contactus" && (
        <section
          style={{
            width: "100%",
            height: "550px",
            display: "flex",
            alignItems: "center",
            backgroundImage: `url(${Kitten})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "60vh",
              backgroundColor: "transparent",
              alignItems: "center",
              justifyContent: "flex-start",
              paddingLeft: "50px",
              flexDirection: "column",
            }}
          >
            <Box>
              <Typography
                variant="h5"
                sx={{
                  color: "#fff",
                  marginTop: "100px",
                  textAlign: "left",
                }}
              >
                Any Questions?
              </Typography>

              <Box
                sx={{
                  width: "520px",
                  height: "1px",
                  backgroundColor: "#fff",
                  marginBottom: "20px",
                  marginLeft: "-100px",
                }}
              />

              <Typography
                variant="h3"
                sx={{
                  color: "#fff",
                  textAlign: "left",
                  maxWidth: "600px",
                  marginBottom: "50px",
                }}
              >
                Schedule a Meeting
              </Typography>
              <a href="/contactus" style={{ textDecoration: "none" }} rel="dofollow">
  <Button
    sx={{
      padding: "10px 30px",
      backgroundColor: "transparent",
      color: "white",
      border: "2px solid #fff",
      borderRadius: "50px",
      fontSize: "1.5rem",
      cursor: "pointer",
      transition: "background-color 0.3s, color 0.3s, transform 0.3s ease",
      "&:hover": {
        backgroundColor: "#fff",
        color: "black",
        transform: "scaleX(1.1)",
      },
    }}
  >
    Contact Us
  </Button>
</a>

            </Box>
          </Box>
        </section>
      )}
      <section
  style={{
    width: "100%",
    backgroundColor: "#ffffff",
    padding: "20px 0",
  }}
>
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: {
        xs: "repeat(2, 1fr)", // ✅ Two columns on mobile for a stylish look
        sm: "repeat(3, 1fr)", // ✅ Three columns on tablet
        md: "repeat(6, auto)", // ✅ Inline layout on desktop
      },
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      gap: { xs: "15px", sm: "40px",md: "80px", },
      margin: "auto",
    }}
  >
    {navItems.map((item, index) => (
        <Link key={index} to={item.link} style={{ textDecoration: "none", color: "inherit" }}>
        <Typography
          variant="h6"
          sx={{
            cursor: "pointer",
            color: "#555",
            fontSize: { xs: "1.2rem", sm: "0.9rem" }, // ✅ Larger on mobile for readability
            fontWeight: "400",
            transition: "color 0.3s ease, transform 0.2s ease",
            "&:hover": {
              color: "#007BFF",
            },
          }}
        >
          {item.label}
        </Typography>
      </Link>  
    
     
    ))}
  </Box>
</section>


<Box
  component="section"
  sx={{
    width: "100%",
    display: "flex",
    flexDirection: { xs: "column", sm: "row" }, // ✅ Stacks on mobile, row on larger screens
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.42)",
    padding: { xs: "20px", sm: "50px" },
    gap: { xs: "30px", sm: "50px" }, // ✅ Adjusts spacing between items
    textAlign: { xs: "center", sm: "left" },
  }}
>
  {/* Left Side - Clickable Address */}
  <Box sx={{ cursor: "pointer" }} onClick={openGoogleMaps}>
    <Typography
      variant="h5"
      sx={{
        fontWeight: "bold",
        color: "#333",
        marginTop: { xs: "10vh", sm: "0px" },

        marginBottom: { xs: "10px", sm: "10px" },
        marginLeft: "-15px",
        textAlign: { xs: "center", sm: "left" }, // ✅ Centers text on mobile
      }}
    >
      <RoomOutlinedIcon fontSize="large" /> Gözde Kuşadası Hastanesi
    </Typography>

    <Typography
      variant="body1"
      sx={{
        color: "#333",
        "&:hover": { color: "#007BFF" },
        whiteSpace: "pre-line", // ✅ Ensures each address line is on a new row
      }}
    >
      Türkmen,{"\n"}
    Rıza Saraç Cd.,{"\n"}
    09400 Kuşadası/Aydın,{"\n"}
    Türkiye
    </Typography>
  </Box>

  {/* Middle - Logo */}
  <Box sx={{ textAlign: "center" }}>
    <img
      src={Logo}
      alt="Gözde Kuşadası Hastanesi Logo"
      style={{
        height: "auto",
        width: "100%",
        maxWidth: "250px", 
        // ✅ Ensures logo scales properly
      }}
    />
  </Box>

  {/* Right Side - Social Media Icons */}
  <Box
    sx={{
      display: "flex",
      gap: "15px",
      justifyContent: { xs: "center", sm: "flex-start" }, // ✅ Centered on mobile, left-aligned on larger screens
    }}
  >
    <IconButton
      component="a"
      href="https://www.facebook.com/Op.Dr.FikretBeyaz/"
      target="_blank"
      sx={{ color: "#1DA1F2" }}
    >
      <FacebookIcon fontSize="large" />
    </IconButton>

    <IconButton
      component="a"
      href="https://www.instagram.com/dr.fikretbeyaz/"
      target="_blank"
      sx={{ color: "#E1306C" }}
    >
      <InstagramIcon fontSize="large" />
    </IconButton>
  </Box>
</Box>
      <footer
        style={{
          height: "70px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "1em",
          color: "white",
          backgroundColor: "rgb(3, 135, 168)",
        }}
      >
        <p style={{ margin: 0 }}>&copy; 2025 - Op. Dr. Fikret Beyaz </p>
      </footer>
    </>
  );
};

export default Cat;

