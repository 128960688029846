import React, { useState } from "react";  // ✅ Fixes 'React' and 'useState' errors
import Logo from "./transparent.png";
import { Box, Button, Typography, Divider, IconButton, Drawer } from "@mui/material";
import { Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material";
import { Phone as PhoneIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom'; // Import Link for internal routing

const menuStructure = {
  About: ["Doctor", "Facility", "Testimonials"],
  Procedures: ["Bariatric Surgery", "Gastric Sleeve", "Gastric Bypass"],
  Gallery: [],
  Kusadasi: [],
  Packages: [],
  FAQ: [],
}
const Topbar = () => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 1000,
      }}
    >
      {/* Top Small Bar */}
      <Box
      sx={{
        backgroundColor: 'rgb(3, 135, 168)',
        color: '#fff',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px',
        // Hide text on xs and sm, show icon on xs and sm, and hide icon on larger sizes
        '& .contact-text': {
          display: { xs: 'block', sm: 'block', md: 'none' },
          fontsize:"1.5rem"
        },
        '& .contact-icon': {
          display: { xs: 'block', sm: 'block', md: 'none' },
        },
      }}
    >
      {/* Hidden on larger screens, shown on xs and sm */}
      <Link to="/contactus" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
        <IconButton className="contact-icon" color="inherit" aria-label="contact us">
          <PhoneIcon />
        </IconButton>
        <Typography className="contact-text" variant="body5">
          Contact Us
        </Typography>
      </Link>


    </Box>
      {/* Sticky Navbar */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        sx={{
          backgroundColor: {xs:"black",sm:"black", md:"rgba(50, 50, 50, 0.9)"},
          transition: "background-color 0.3s ease",
          position: "sticky",
          top: "30px", // Adjusted to match original behavior
          height: {xs:"70px",sm:"70px",md:"90px"},
        }}
        onMouseLeave={() => setOpenDropdown(null)} // Close menu when mouse leaves navbar
      >
        {/* LOGO */}
                  <Link to="/" style={{ textDecoration: "none" }}>
            <Box 
              component="img"
              src={Logo}
              alt="Logo"
              sx={{
                height: { xs: "90px", sm: "120px", md: "140px" },
                width: { xs: "90px", sm: "120px", md: "140px" },
                cursor: "pointer",
                paddingLeft: "0px",
              }}
            />
          </Link>


        {/* NAVBAR MENU */}
        <Box display={{ xs: "none", sm:"none", md: "flex" }} alignItems="center" gap={10}>
          {Object.entries(menuStructure).map(([menuKey, subItems]) => (
           <Box key={menuKey} position="relative">
           <Link 
             to={!subItems.length ? `/${menuKey.toLowerCase()}` : "#"} 
             style={{ textDecoration: 'none' }}
             onMouseEnter={() => setOpenDropdown(menuKey)} // Open on hover
           >
             <Button
               sx={{
                 color: "#fff",
                 fontSize: "16px",
                 textTransform: "none",
                 "&:hover": { color: "#ddd" },
               }}
             >
               {menuKey}
             </Button>
           </Link>
         

              {/* DROPDOWN MENU */}
              {openDropdown === menuKey && subItems.length > 0 && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    backgroundColor: "rgb(240, 255, 224)",
                    transition: "background-color 0.3s ease",
                    boxShadow: 2,
                    borderRadius: 1,
                    zIndex: 1000,
                    minWidth: "180px",
                    padding: "0px 0",
                  }}
                  onMouseLeave={() => setOpenDropdown(null)} // Close when leaving dropdown
                >
                  {subItems.map((subItem, index) => (
                    <React.Fragment key={subItem}>
                                        <Link
                      to={`/${menuKey.toLowerCase()}/${subItem.toLowerCase().replace(/\s+/g, "")}`}
                      style={{
                        textDecoration: "none",
                      }}
          >
                      <Typography
                        sx={{
                          padding: "12px 12px",
                          cursor: "pointer",
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#333",
                          transition: "background-color 0.3s",
                          "&:hover": { backgroundColor: "rgba(3, 135, 168, 0.6)", color: "#fff" },
                        }}
                      >
                        {subItem}
                      </Typography>
                    </Link>


                      {/* Add Divider Except for the Last Item */}
                      {index < subItems.length - 1 && (
                        <Divider sx={{ backgroundColor: "rgb(3, 135, 168)", opacity: 0.6, height:"3px" }} />
                      )}
                    </React.Fragment>
                  ))}
                </Box>
              )}
            </Box>
          ))}
        </Box>
                {/* Hamburger BUTTON */}

        <IconButton
              sx={{
                display: { xs: "block",sm:"block", md: "none" }, // ✅ Hidden on desktop
                color: "white", // ✅ Ensure visibility against dark navbar
              }}
              onClick={() => setMobileMenuOpen(true)}
            >
              <MenuIcon fontSize="large" />
        </IconButton>
        {/* Hamburger Menu */}
        {/* MOBILE MENU DRAWER */}
      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        sx={{ "& .MuiDrawer-paper": { width: "70%", backgroundColor:"rgb(3, 135, 168)", color: "#fff" } }}
      >
        <Box display="flex" justifyContent="flex-end" p={2}>
          <IconButton onClick={() => setMobileMenuOpen(false)}>
            <CloseIcon sx={{ color: "#fff" }} />
          </IconButton>
        </Box>
        <Box display="flex" flexDirection="column" gap={3} px={4}>
          {Object.entries(menuStructure).map(([menuKey, subItems]) => (
            <Box key={menuKey}>
            <Link
              to={subItems.length > 0 
                ? `/${menuKey.toLowerCase()}/${subItems[0].toLowerCase().replace(/\s+/g, "")}` 
                : `/${menuKey.toLowerCase()}`
              }
              onClick={() => setMobileMenuOpen(false)} // ✅ Closes the drawer after clicking
              style={{color: "inherit", textDecoration: "none" }}
            >
              <Typography
                variant="h6"
                sx={{ cursor: "pointer" }}
              >
                {menuKey}
              </Typography>
            </Link>
          
            {subItems.length > 0 && (
              <Box pl={2}>
                {subItems.map((subItem) => (
                  <Link
                    key={subItem}
                    to={`/${menuKey.toLowerCase()}/${subItem.toLowerCase().replace(/\s+/g, "")}`}
                    onClick={() => setMobileMenuOpen(false)} // ✅ Close drawer after clicking
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Typography
                      sx={{ fontSize: "16px", cursor: "pointer", mt: 1, paddingY: 1 }}
                    >
                      {subItem}
                    </Typography>
                  </Link>
                  ))}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Drawer>


        {/* CONTACT BUTTON */}
        <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
  <Link to="/contactus" style={{ textDecoration: "none" }}>
    <Button
      variant="outlined"
      sx={{
        color: "white",
        borderColor: "white",
        borderRadius: "50px",
        padding: "10px 30px",
        fontSize: "1.2rem",
        textTransform: "none",
        transition: "all 0.3s",
        "&:hover": { backgroundColor: "rgba(3, 135, 168, 0.6)", color: "black", borderColor: "rgba(3, 135, 168, 0.6)" },
      }}
    >
      Contact Us
    </Button>
  </Link>
</Box>

      </Box>
    </Box>
  );
};
export default Topbar;