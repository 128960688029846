import "@fontsource/montserrat/700.css"; // Bold weight
import { Typography, Box } from "@mui/material";

function Header({ backgroundImage, faqImage, headerText }) {

    
  return (

    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row"}, // Column layout on mobile, row on larger screens
        height: { xs: "60vh", md: "100vh"}, // Half viewport height on mobile
        width: "100%",
        position: "relative",
        marginTop: {xs:"100px", sm:"100px", md:"0"}
      }}
    >
      {/* Left section with blur (Desktop) / FAQ Image (Mobile) */}
      <Box
        sx={{
          flex: "1",
          position: "relative",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: { xs: "left", md: "center" }, // Right-aligned title on mobile
          alignItems: "center",
          textAlign: "center",
          padding: "20px",
          height: "100%",
          backgroundImage: {
            xs: `url(${faqImage})`, // FAQ image on mobile
            md: `url(${backgroundImage})`, // Original image on larger screens
          },
          "::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(255, 255, 255, 0.35)", // White overlay for blur effect
            backdropFilter: { md: "blur(10px)" },
          },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            position: "relative", // Ensures text is above blur
            fontSize: { xs: "1.4rem", md: "3.5rem" },
                        marginBottom: "10px",
            color: "rgb(2, 32, 52)",
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 700,
            textAlign: { xs: "center", md: "center" }, // Align right on mobile, center on desktop
            width: { xs: "100%", md: "auto" }, // Prevents excessive width on mobile
          }}
        >
          {headerText}
        </Typography>
      </Box>

      {/* Right section (Hidden on Mobile) */}
      <Box
        sx={{
          flex: "1",
          display: { xs: "none", md: "block" }, // Hide right section on mobile
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "100%",
          backgroundImage: `url(${faqImage})`, // Background remains the FAQ image on desktop
        }}
      />
    </Box>

  );
}

export default Header;

