/*
import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import { Link } from 'react-router-dom';
import 'yet-another-react-lightbox/styles.css';
import {
  Captions,
  Fullscreen,
  Thumbnails,
  Zoom,
} from 'yet-another-react-lightbox/plugins';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import Wife from "./wife.jpg"
import First from "./images/1.jpeg"
import Second from "./images/2.jpeg"
import Third from "./images/3.jpeg"
import Fourth from "./images/4.jpeg"
import Fifth from "./images/5.jpeg"
import Sixth from "./images/6.jpeg"
import Seventh from "./images/7.jpeg"
import Eight from "./images/8.jpeg"
import Nine from "./images/9.jpeg"



export const slides = [
    {
      src:  Nine,
      title: 'Image 1',
      description: 'This is the first image.',
    },
    {
      src: First,
      title: 'Image 2',
      description: 'This is the second image.',
    },
    {
      src: Third,
      title: 'Image 3',
      description: 'This is the third image.',
    },
    {
      src:  Second,
      title: 'Image 4',
      description: 'This is the first image.',
    },
    {
      src:  Fifth,
      title: 'Image 5',
      description: 'This is the first image.',
    },
    {
      src: Sixth,
      title: 'Image 6',
      description: 'This is the first image.',
    },
    {
      src:  Seventh,
      title: 'Image 7',
      description: 'This is the first image.',
    },
    {
      src:  Fourth,
      title: 'Image 8',
      description: 'This is the first image.',
    },
    {
      src:  Eight,
      title: 'Image 9',
      description: 'This is the first image.',
    }
  ];

 export const renderNavigation = (totalPages, currentPage,hoveredPage, setHoveredPage) => {

    const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    return (
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', margin: '20px 0' }}>
      {currentPage > 1 && (
       <Link
       to={`/gallery/${currentPage - 1 }`} // Link to the next page
       style={{
         marginTop: "37px",
         background: "linear-gradient(90deg, rgb(66, 73, 207),rgb(70, 78, 230)",
         color: "white",
         border: "none",
         padding: "5px 10px",
         borderRadius: "1px",
         fontSize: "1rem",
         cursor: "pointer",
         transition: "all 0.1s ease",
         textDecoration: "none"
       }}
       onMouseOver={(e) => {
         e.target.style.background = "linear-gradient(90deg, rgb(90, 100, 240), rgb(217, 212, 228))"; // Lighter gradient
         e.target.style.color = "black"; // Change text color to black
         e.target.style.transform = "scale(1)"; // Slight scaling effect
       }}
       onMouseOut={(e) => {
         e.target.style.background = "linear-gradient(90deg, rgb(66, 73, 207),rgb(70, 78, 230)"; // Original gradient
         e.target.style.color = "white"; // Reset text color to white
         e.target.style.transform = "scale(1)"; // Reset scaling
       }}
     >
       Prev
     </Link>
      )}
    
      {pages.map((page) => (
        <Link
          key={page}
          to={page === 1 ? '/gallery' : `/gallery/${page}`}// Assuming your routes are like `/gallery/1`, `/gallery/2`, `/gallery/3`
          style={{
            textDecoration: 'none',
            backgroundColor: page === currentPage ? "rgb(157, 161, 235)" :'transparent'  ,
            fontSize: '18px',
            marginTop: '40px',
            color:
              page === currentPage
                ? 'black'
                : page === hoveredPage
                ? '#555'
                : 'black', // Change color on hover
            fontWeight: page === currentPage ? 'bold' : 'normal',
            width: '30px', // Fixed width for the square
            height: '30px', // Fixed height for the square
            lineHeight: '30px', // Align text vertically within the square
            textAlign: 'center', // Align text horizontally within the square
            borderRadius: '5px', // Optional: Add slight rounding for the square corners          
            transition: 'color 0.3s ease',
          }}
          onMouseEnter={() => setHoveredPage(page)}
          onMouseLeave={() => setHoveredPage(null)}
        >
          {page}
        </Link>
      ))}
    
      {currentPage < pages.length && (
        <Link
          to={`/gallery/${currentPage + 1}`} // Link to the next page
          style={{
            marginTop: "37px",
            background: "linear-gradient(90deg, rgb(66, 73, 207),rgb(70, 78, 230)",
            color: "white",
            border: "none",
            padding: "5px 10px",
            borderRadius: "1px",
            fontSize: "1rem",
            cursor: "pointer",
            transition: "all 0.1s ease",
            textDecoration: "none"
          }}
          onMouseOver={(e) => {
            e.target.style.background = "linear-gradient(90deg, rgb(90, 100, 240), rgb(217, 212, 228))"; // Lighter gradient
            e.target.style.color = "black"; // Change text color to black
            e.target.style.transform = "scale(1)"; // Slight scaling effect
          }}
          onMouseOut={(e) => {
            e.target.style.background = "linear-gradient(90deg, rgb(66, 73, 207),rgb(70, 78, 230)"; // Original gradient
            e.target.style.color = "white"; // Reset text color to white
            e.target.style.transform = "scale(1)"; // Reset scaling
          }}
        >
          Next
        </Link>
      )}
    </div>
    
    );
 }
function Gallery() {
  const [currentIndex, setCurrentIndex] = useState(-1); // Tracks the currently open slide
  const [hoveredPage, setHoveredPage] = useState(null);

  return (
    <div style={{ padding: '80px 80px', textAlign: 'center', marginTop: '60px'}}>

      <h2>Before & After Gallery</h2>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '30px', justifyContent: 'center', marginTop: '60px'}}>
        {slides.map((slide, index) => (
          <img
            key={index}
            src={slide.src}
            alt={slide.title || `Image ${index + 1}`}
            style={{ width: '30%', height: '30%', cursor: 'pointer', objectFit: 'cover', borderRadius: '5px' }}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>

      {currentIndex >= 0 && (
        <Lightbox
          plugins={[ Fullscreen, Zoom, Thumbnails]}
          open={currentIndex >= 0}
          close={() => setCurrentIndex(-1)}
          slides={slides}
          index={currentIndex}
        />
      )}
    {renderNavigation(5,1,hoveredPage, setHoveredPage)}

    </div>
  );
}

export default Gallery;
*/
import GalleryTemplate from "./gallerytemplate";
import First from "./images/1.jpeg"
import Second from "./images/2.jpeg"
import Third from "./images/3.jpeg"
import Fourth from "./images/4.jpeg"
import Fifth from "./images/5.jpeg"
import Sixth from "./images/6.jpeg"
import Seventh from "./images/7.jpeg"
import Eight from "./images/8.jpeg"
import Nine from "./images/9.jpeg"
export const slides = [
  {
    src:  Nine,
    title: 'Image 1',
    description: 'This is the first image.',
  },
  {
    src: First,
    title: 'Image 2',
    description: 'This is the second image.',
  },
  {
    src: Third,
    title: 'Image 3',
    description: 'This is the third image.',
  },
  {
    src:  Second,
    title: 'Image 4',
    description: 'This is the first image.',
  },
  {
    src:  Fifth,
    title: 'Image 5',
    description: 'This is the first image.',
  },
  {
    src: Sixth,
    title: 'Image 6',
    description: 'This is the first image.',
  },
  {
    src:  Seventh,
    title: 'Image 7',
    description: 'This is the first image.',
  },
  {
    src:  Fourth,
    title: 'Image 8',
    description: 'This is the first image.',
  },
  {
    src:  Eight,
    title: 'Image 9',
    description: 'This is the first image.',
  }
];
function Gallery5() {


  return (
    <GalleryTemplate
      slides={slides}
      pageNumber={5}  // Current Page
      totalPages={5}  // Total Number of Pages
      galleryTitle="Before & After Gallery"
    />
  );


}
export default Gallery5;
