import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { motion } from "framer-motion"; // For smooth animations
import Background from "./cover.webp"; // Replace with actual background image

const HomePageFirstSection = () => {
  return (
    <Box
    sx={{
      position: "relative",
      height: "100%",
      width: "100%",
      display: "flex",
      textAlign: {xs:"center",md:"left"},
      color: "white",
      padding: {xs:"2ch", md:"140px"},
      marginTop: { xs: "-30px", md: "0" },
    }}
  >
    <img
      src={Background} // ✅ Make sure Background is properly imported
      alt="Background"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
      }}
    />
  
      {/* Overlay for better readability */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.6)", // Dark overlay
        }}
      />

      {/* Content Box */}
      <Box sx={{ position: "relative", zIndex: 1, maxWidth: "900px" }}>
        {/* Title */}
        <Typography variant="h1" sx={{ fontWeight: "bold", mb: 2, fontSize: "2.5rem" }}>
          Op. Dr. Fikret Beyaz
        </Typography>

        {/* Subtitle */}
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 3, color: "rgba(185, 179, 18, 0.98)" }}>
          Experience, Caring, Satisfaction
        </Typography>

        
        {/* Statistics Section */}
        <Grid container spacing={4} justifyContent="center" sx={{ mt: 6 }}>
          {[
            { value: "20,000+", text: "Over 20,000 successful surgeries" },
            { value: "20+", text: "20 years experience" },
            { value: "20,000", text: "Over 20,000 happy people" },
          ].map((stat, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: index * 0.3 }}
              >
                <Typography variant="h3" sx={{ fontWeight: "bold", color: "#FFD700", zIndex: 1, opacity: "0.9" }}>
                  {stat.value}
                </Typography>
                <Typography variant="h6" sx={{ color: "white", opacity: 0.8 }}>
                  {stat.text}
                </Typography>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default HomePageFirstSection;
