import React from "react";
import { Box, Typography} from "@mui/material";
import Slider from "./slider"; // Import the Slider component
import Help from "./help.png"
import Gallery2 from "./gallery02.jpg"
import Doctor from "../operations/gastric_sleeve/doctor.png"
import Slider2 from "../operations/gastric_sleeve/testimonial"
import BMICalculator from "../operations/bariatric/bmi"
import { Link } from "react-router-dom";
import KusadasiSection from "./kusadasi";
import HomePageFirstSection from "./coverpage"
import Second from "../global/second";

const HomePage = () => {



  return (
    <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6", backgroundColor: "#f4f4f9", color: "#333" }}>
      <section style={{  height: "150%", marginTop:"120px"}}>

<HomePageFirstSection/>
</section>

      <section style={{ backgroundColor: "white", padding: "40px 20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
  <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "center", gap: "40px", width: "100%", maxWidth: "1200px" }}>
    {/* Left Side - Image with Faster Animation */}
    <Box
      sx={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        animation: "slideInLeft 1s ease-in-out"
      }}
    >
      <img
        src= {Help}
        alt="Life Changing Decision"
        style={{ width: "100%", maxWidth: "500px", borderRadius: "12px" }}
      />
    </Box>

    {/* Right Side - Text with Delayed Animation */}
    <Box
      sx={{
        flex: 1,
        animation: "slideInRight 2s ease-in-out 0.5s",
        animationFillMode: "both",
        textAlign: { xs: "center", md: "left" }
      }}
    >
      <Typography variant="h2" sx={{ fontWeight: "bold", marginBottom: "20px", color: "#333" }}>
        Life Changing Decision
      </Typography>
      <Typography sx={{ color: "#555", fontSize: "1.2rem", lineHeight: "1.8" }}>
        We are here to help you achieve your dream life. Our dedicated team supports you every step of the way in your transformative journey. Let us guide you toward a healthier, happier future.
      </Typography>
    </Box>
  </Box>

  {/* Keyframes for Animation */}
  <style>
    {`
      @keyframes slideInLeft {
        from {
          transform: translateX(-100%);
          opacity: 0;
        }
        to {
          transform: translateX(0);
          opacity: 1;
        }
      }

      @keyframes slideInRight {
        from {
          transform: translateX(100%);
          opacity: 0;
        }
        to {
          transform: translateX(0);
          opacity: 1;
        }
      }
    `}
  </style>
</section>
<Box
  sx={{
    backgroundColor: "#f9f9f9",
    maxWidth: "100vw", // Prevents horizontal overflow
    width: "100%",
    padding: "40px",
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap", // Ensures content doesn't overflow
    overflow: "hidden", // Prevents scrollbars
  }}
>
  {/* Right Side: Text Section */}
  <Box
    flex="1"
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: { xs: "center", md: "flex-start" },
      textAlign: { xs: "center", md: "left" },
      padding: "16px",
      width: "100%", // Ensures no horizontal overflow
    }}
  >
    <Typography
      variant="h6"
      sx={{
        fontWeight: "bold",
        marginBottom: "10px",
        color: "rgb(25, 93, 138)",
        fontSize: { xs: "1.4rem", md: "1.5rem" },
        paddingLeft: { xs: "0px", md: "40px" }, // Less margin to prevent overflow
        maxWidth: "90vw", // Keeps text inside viewport
      }}
    >
      Quality Choices
    </Typography>

    <Box
      sx={{
        height: "1px",
        width: { xs: "80%", md: "100%" },
        backgroundColor: "black",
        marginTop: "8px",
        marginBottom: { xs: "40px", md: "150px" },
        marginLeft: "auto",
        marginRight: "auto",
      }}
    />

    <Typography
      variant="h3"
      sx={{
        fontWeight: "bold",
        marginBottom: "20px",
        color: "#333",
        fontSize: { xs: "2rem", md: "3rem" },
        paddingLeft: { xs: "0px", md: "40px" },
        maxWidth: "90vw",
      }}
    >
      Popular Procedures
    </Typography>

    <Typography
      sx={{
        color: "#555",
        fontSize: { xs: "1.5rem", md: "2rem" },
        textAlign: { xs: "center", md: "left" },
        width: { xs: "90%", md: "600px" },
        maxWidth: "90vw", // Prevents overflow
        marginBottom: { xs: "50px", md: "100px" },
        paddingLeft: { xs: "0px", md: "40px" },
      }}
    >
      Bariatric surgery is the first step of our program. Each procedure is selected for you after carefully examining the individual’s needs, goals, and priorities.
    </Typography>
  </Box>

  {/* Left Side: Slider Component */}
  <Box
    flex="1"
    display="flex"
    justifyContent="center"
    alignItems="center"
    sx={{
      width: { xs: "100%", md: "auto" },
      padding: { xs: "0px", md: "36px" },
    }}
  >
    <Slider />
  </Box>
</Box>


      <Box
      sx={{
        width: "100%",
        minHeight: "600px",
        display: "flex",
        flexDirection: { xs: "column", md: "row" }, // Responsive with sx
        alignItems: "center",
        backgroundImage: `url(${Gallery2})`,
        backgroundSize: "cover",
        backgroundPosition: {xs:"none",md: "center"},
        position: "relative",
      }}
    >
      <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.6)", // Dark overlay
                zIndex: 1, // Ensures it is above background but below content

              }}
            />
      {/* Left Side - Dark Overlay with Title */}
      <Box
        sx={{
          width: "100%",
          height: {xs:"50vh",md:"60vh"},
          backgroundColor: "transparent", // Transparent background
          display: "flex",
          alignItems: "center",
          paddingTop:{xs:"10vh", md: "none"}, // Vertically center the content
          justifyContent: "flex-start", // Align content to the left
          paddingLeft: {xs:"0px", md:"50px"}, // Space from the left edge
          position: "relative", // Ensures text is above overlay
          zIndex: 2, // Moves content above overlay
    
        }}
      >
        <Box>
          {/* Title */}
          <Typography
            variant="h5"
            sx={{
              color: "#fff", // White text for visibility
              fontWeight: "bold",
              marginBottom: "10px",
              textAlign:{xs:"center", md:"left"}
            }}
          >
            Transform Your Life
          </Typography>

          {/* White Line */}
          <Box
            sx={{
              width: {xs:"auto", md:"400px"},
              height: "1px",
              backgroundColor: "#fff", // White line
              marginBottom: "20px",
            }}
          />

          {/* Subtitle */}
          <Typography
            variant="h3"
            sx={{
              color: "#fff", // White text for contrast
              textAlign: {xs:"center", md:"left"}, // Align text to the left
              maxWidth: "600px",
            }}
          >
            Dramatic Life Quality Changes
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          width:  { xs: "70%", md: "29%" },
          paddingRight: "0px",
          backgroundColor: "transparent",
          paddingTop: "40px",
          paddingBottom: "0px",
          textAlign: "right",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: { xs: "10px", md: "0" }, // Adjust for mobile
          position: "relative", // Ensures text is above overlay
          zIndex: 2, // Moves content above overlay

        }}
      >
        {/* Oval Outlined Button */}
        <Link to="/gallery" style={{ textDecoration: "none" }}>
              <button
                style={{
                  padding: "10px 30px",
                  backgroundColor: "transparent", // No fill
                  color: "white",
                  border: "2px solid #fff", // White border for outline
                  borderRadius: "50px", // Oval shape
                  fontSize: "1.5rem",
                  cursor: "pointer",
                  transition: "background-color 0.3s, color 0.3s, transform 0.3s ease",
                  opacity: 0,
                  animation: "fadeIn 1s forwards",
                  animationDelay: "1.5s",
                  transformOrigin: "left",
                }}
                onMouseOver={(e) => {
                  e.target.style.backgroundColor = "#fff";
                  e.target.style.color = "black"; // Text turns dark when hovered
                  e.target.style.transform = "scaleX(1.5)";
                }}
                onMouseOut={(e) => {
                  e.target.style.backgroundColor = "transparent";
                  e.target.style.color = "white"; // Text turns white when not hovered
                  e.target.style.transform = "scaleX(1)";
                }}
              >
                Explore Gallery
              </button>
            </Link>

      </Box>

      {/* Keyframes for Fade-In Animation */}
      <style>{`
        @keyframes fadeIn {
          0% {
            opacity: 0;
            transform: translateY(20px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </Box>
    <Box
  sx={{
    width: "100%",
    height: "100%",
    margin: "0",
    padding: { xs: "10px", md: "20px" }, // Added 10px padding for mobile, kept 40px for desktop
  }}
>
  <Box
    sx={{
      textAlign: "center",
      padding: { xs: "10px", md: "20px" }, // Added 10px padding for mobile
    }}
  >
    <Typography
      variant="h2"
      sx={{
        fontSize: "2.5rem",
        color: "#555",
        fontWeight: "bold",
        marginBottom: "10px",

      }}
    >
      Are you a candidate for Surgery?
    </Typography>

    <Typography
      variant="body1"
      sx={{
        fontSize: "1.4rem",
        color: "#555",
        maxWidth: "800px",
        margin: "0 auto",
        lineHeight: "1.6",
        marginBottom: "80px",
        padding: { xs: "10px", md: "0" }, // Added 10px padding for mobile
      }}
    >
      Bariatric surgery can greatly help people dealing with health issues caused
      by obesity. It's particularly useful for those with problems like type 2
      diabetes, high blood pressure, osteoarthritis, and sleep apnea. The surgery
      can lead to losing a lot of weight, which can improve both health and
      confidence. It's most effective for individuals who are more than 100 pounds
      over their ideal weight or have a body mass index (BMI) over 40. Those with a
      BMI between 30 and 39.9 can also qualify if they have serious health issues
      related to obesity, such as diabetes or high blood pressure. Additionally,
      candidates should find it hard to lose weight through diet and exercise alone
      and must not have issues with drug or alcohol use.
    </Typography>

    <Typography
      variant="h2"
      sx={{
        fontSize: "2.5rem",
        color: "#555",
        fontWeight: "bold",
        marginBottom:"10px"
      }}
    >
      How do we choose the Surgery?
    </Typography>

    <Typography
      variant="body1"
      sx={{
        fontSize: "1.4rem",
        color: "#555",
        maxWidth: "800px",
        margin: "0 auto",
        lineHeight: "1.6",
        marginBottom: "80px",
        padding: { xs: "10px", md: "0" }, // Added 10px padding for mobile
      }}
    >
      Our team will complete a comprehensive consultation to decide which weight
      loss solutions are ideal for your goals. Our dedication to safety requires
      detailed and precise patient screenings. We will work as a team to determine
      what you need.
    </Typography>
  </Box>
</Box>

      <section style={{ width: "100%", height: "50%", margin: "0", padding: "0" }}>
         <BMICalculator />
      </section>
      <Second
      title="Why Choose Us"
      subtitle="Meet the Doctor"
      text={`Dr. Fikret Beyaz is your dedicated ally in the battle against obesity and type 2 diabetes.
         He is not just a doctor; he's a compassionate healer who understands the physical and emotional challenges you face. With Dr. Beyaz by your side,
          you can look forward to a brighter, healthier future. He's not just a surgeon; he's a pioneer in improving the lives of countless individuals who have struggled with obesity and side effects.
           Like how he always say to their patients: "everybody deserves a healthy life."`}
      image={Doctor}
    />


          <section
      style={{
        width: "100%",
        height: "80%",
        margin: "0",
        padding: "0",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        animation: "fadeIn 2s ease-in-out",
      }}
    >
      <Slider2 />
      
      {/* Animation Keyframes */}
      <style>{`
        @keyframes fadeIn {
          0% {
            opacity: 0;
            transform: translateY(20px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </section>
    <section style={{ width: "100%", height: "100%", margin: "0", padding: "20px",backgroundColor:"white" }}>
        <div style={{ textAlign: "center", padding: "40px 0" }}>
        <h2 style={{ fontSize: "2.5rem", color: "#555", fontWeight: "bold" }}>
          Welcome to Kusadasi
         </h2>
         <p style={{ fontSize: "1.4rem", color: "#55", maxWidth: "800px", margin: "0 auto", lineHeight: "1.6" ,marginBottom: "30px"}}>

          Do you want to enjoy one of the best coastline place in the world, while having your bariatric surgery? If your answer is yes, 
          Kusadasi is the right place for you.
          </p>
          <KusadasiSection />
          <Link to="/kusadasi" style={{ textDecoration: "none", color: "inherit" }}>
  <Box
    sx={{
      textAlign: "center",
      marginTop: "20px",
      cursor: "pointer",
      transition: "transform 0.3s ease, color 0.3s ease",
      "&:hover": {
        transform: "scale(1.01)",
        color: "rgb(3, 135, 168)", // Change color on hover
      },
    }}
  >
    <Typography variant="h7" sx={{ fontSize: { xs: "1.4rem", md: "1rem" }, fontWeight: "bold" }}>
      <b>Click to see more about Kusadasi</b>
    </Typography>
  </Box>
</Link>


          

         </div>
      </section>
      
    </div>
  );
};

export default HomePage;
