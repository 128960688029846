import { Typography, Container, Divider } from "@mui/material";
import { motion } from "framer-motion";

function About({ title, text }) {
  return (
    <motion.section
      initial={{ opacity: 0, y: -50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
      viewport={{ once: true, amount: 0.5 }}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f9f9f9",
        minHeight: "80vh",
        width: "100%",
        padding: "8vh 4vw", // Responsive padding
        marginBottom: "3px",
      }}
    >
      {/* Centered Content */}
      <Container maxWidth="md" sx={{ textAlign: "center" }}>
        {/* Dynamic Section Title */}
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            marginBottom: "16px",
            color: "#333",
            fontSize: { xs: "2rem", md: "2.8rem" }, // Responsive text size
          }}
        >
          {title}
        </Typography>

        {/* Underline Divider */}
        <Divider
  sx={{
    height: "4px",
    maxWidth: "80ch",
    borderColor: "rgb(25, 99, 148)", // Use borderColor for full color visibility
    borderBottomWidth: "4px", // Make the border thicker
    borderBottomStyle: "solid", // Ensures it's visible
    margin: "0 auto 24px auto",
  }}
/>


        {/* Dynamic Description Text */}
        <Typography
          sx={{
            color: "#555",
            fontSize: { xs: "1.1rem", md: "1.2rem" }, // Responsive font size
            maxWidth: "60ch", // Limits text width for better readability
            margin: "0 auto",
            lineHeight: "1.6",
          }}
        >
          {text}
        </Typography>
      </Container>
    </motion.section>
  );
}

export default About;
