import React from "react";
import { Box, Typography } from "@mui/material";

const First = ({ imageSrc, title, text }) => {
  return (
    <Box
      component="section"
      sx={{
        backgroundColor: "rgb(234, 244, 246)",
        padding: { xs: "20px", md: "32px" },
        marginTop: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // ✅ Stacks on mobile, row on desktop
          alignItems: "center",
          textAlign: { xs: "center", md: "left" },
        }}
      >
        {/* Left Side - Image */}
        <Box
          component="img"
          src={imageSrc}
          alt={title}
          sx={{
            width: { xs: "100%", md: "40%" }, // ✅ Full width on mobile, 40% on larger screens
            height: "auto",
            borderRadius: "8px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            marginRight: { md: "32px" }, // ✅ Space between image & text
            marginBottom: { xs: "16px", md: "0" }, // ✅ Adjust spacing for mobile
          }}
        />

        {/* Right Side - Text */}
        <Box sx={{ width: { xs: "100%", md: "60%" } }}>
          {/* Title */}
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              marginBottom: { xs: "20px", md: "40px" },
              color: "#195D8A",
              fontSize: { xs: "2rem", md: "2.5rem" }, // ✅ Scales title size
            }}
          >
            {title}
          </Typography>

          {/* Body Text */}
          <Typography
            sx={{
              color: "#555",
              fontSize: { xs: "1.1rem", md: "1.2rem" },
              lineHeight: "1.8",
            }}
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default First;
