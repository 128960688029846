/*
import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import { Link } from 'react-router-dom';
import 'yet-another-react-lightbox/styles.css';
import {
  Fullscreen,
  Thumbnails,
  Zoom,
} from 'yet-another-react-lightbox/plugins';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

const renderNavigation = (totalPages, currentPage, hoveredPage, setHoveredPage) => {
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  return (
    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', margin: '20px 0' }}>
      {currentPage > 1 && (
        <Link
          to={`/gallery/${currentPage - 1}`}
          style={{
            marginTop: "37px",
            background: "linear-gradient(90deg, rgb(66, 73, 207),rgb(70, 78, 230)",
            color: "white",
            border: "none",
            padding: "5px 10px",
            borderRadius: "1px",
            fontSize: "1rem",
            cursor: "pointer",
            textDecoration: "none",
            transition: "all 0.1s ease",
          }}
          onMouseOver={(e) => {
            e.target.style.background = "linear-gradient(90deg, rgb(90, 100, 240), rgb(217, 212, 228))";
            e.target.style.color = "black";
          }}
          onMouseOut={(e) => {
            e.target.style.background = "linear-gradient(90deg, rgb(66, 73, 207),rgb(70, 78, 230)";
            e.target.style.color = "white";
          }}
        >
          Prev
        </Link>
      )}

      {pages.map((page) => (
        <Link
          key={page}
          to={`/gallery/${page}`}
          style={{
            textDecoration: 'none',
            backgroundColor: page === currentPage ? "rgb(157, 161, 235)" : 'transparent',
            fontSize: '18px',
            marginTop: '40px',
            color: page === currentPage ? 'black' : page === hoveredPage ? '#555' : 'black',
            fontWeight: page === currentPage ? 'bold' : 'normal',
            width: '30px',
            height: '30px',
            lineHeight: '30px',
            textAlign: 'center',
            borderRadius: '5px',
            transition: 'color 0.3s ease',
          }}
          onMouseEnter={() => setHoveredPage(page)}
          onMouseLeave={() => setHoveredPage(null)}
        >
          {page}
        </Link>
      ))}

      {currentPage < totalPages && (
        <Link
          to={`/gallery/${currentPage + 1}`}
          style={{
            marginTop: "37px",
            background: "linear-gradient(90deg, rgb(66, 73, 207),rgb(70, 78, 230)",
            color: "white",
            border: "none",
            padding: "5px 10px",
            borderRadius: "1px",
            fontSize: "1rem",
            cursor: "pointer",
            textDecoration: "none",
            transition: "all 0.1s ease",
          }}
          onMouseOver={(e) => {
            e.target.style.background = "linear-gradient(90deg, rgb(90, 100, 240), rgb(217, 212, 228))";
            e.target.style.color = "black";
          }}
          onMouseOut={(e) => {
            e.target.style.background = "linear-gradient(90deg, rgb(66, 73, 207),rgb(70, 78, 230)";
            e.target.style.color = "white";
          }}
        >
          Next
        </Link>
      )}
    </div>
  );
};

const GalleryTemplate = ({ slides, pageNumber, totalPages, galleryTitle }) => {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [hoveredPage, setHoveredPage] = useState(null);

  return (
    <div style={{ padding: '80px 80px', textAlign: 'center', marginTop: '60px' }}>
      <h2>{galleryTitle}</h2>

      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '30px', justifyContent: 'center', marginTop: '60px' }}>
        {slides.map((slide, index) => (
          <img
            key={index}
            src={slide.src}
            alt={slide.title || `Image ${index + 1}`}
            style={{ width: '30%', height: '30%', cursor: 'pointer', objectFit: 'cover', borderRadius: '5px' }}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>

      {currentIndex >= 0 && (
        <Lightbox
          plugins={[Fullscreen, Zoom, Thumbnails]}
          open={currentIndex >= 0}
          close={() => setCurrentIndex(-1)}
          slides={slides}
          index={currentIndex}
        />
      )}

      {renderNavigation(totalPages, pageNumber, hoveredPage, setHoveredPage)}
    </div>
  );
};

export default GalleryTemplate;
*/
import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import { Link } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import 'yet-another-react-lightbox/styles.css';
import {
  Fullscreen,
  Thumbnails,
  Zoom,
} from 'yet-another-react-lightbox/plugins';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

const renderNavigation = (totalPages, currentPage, hoveredPage, setHoveredPage) => {
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  return (
    <Box
  sx={{
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "10px",
    margin: "20px 0",
    flexDirection: { xs: "column", sm: "row" }, // ✅ Moves Prev above & Next below on mobile
    alignItems: "center",
  }}
>

      {/* Previous Page */}
      {currentPage > 1 && (
        <Button
          component={Link}
          to={currentPage === 2 ? `/gallery` : `/gallery/${currentPage - 1}`}
          sx={navButtonSx}
        >
          Prev
        </Button>
      )}

      {/* Numbered Links */}
      <Box sx={{ display: "flex", gap: { xs: "5px", sm: "10px" }, justifyContent: "center" }}>

      {pages.map((page) => (
        <Button
          key={page}
          component={Link}
          to={page === 1 ? `/gallery` : `/gallery/${page}`}
          sx={{
            ...pageLinkSx,
            backgroundColor: page === currentPage ? "rgb(157, 161, 235)" : "transparent",
            color: page === currentPage ? "black" : page === hoveredPage ? "#555" : "black",
            fontWeight: page === currentPage ? "bold" : "normal",
          }}
          onMouseEnter={() => setHoveredPage(page)}
          onMouseLeave={() => setHoveredPage(null)}
        >
          {page}
        </Button>
      ))}
        </Box>


      {/* Next Page */}
      {currentPage < totalPages && (
        <Button component={Link} to={`/gallery/${currentPage + 1}`} sx={navButtonSx}>
          Next
        </Button>
      )}
    </Box>
  );
};

// **MUI `sx` Styles**
const navButtonSx = {
  background: "linear-gradient(90deg, rgb(66, 73, 207), rgb(70, 78, 230))",
  color: "white",
  padding: { xs: "10px 20px", sm: "4px 4px" },
  borderRadius: { xs: "8px", sm: "1px" },
  fontSize: { xs: "1rem", sm: "1rem" },
  cursor: "pointer",
  textDecoration: "none",
  transition: "all 0.1s ease",
  marginTop: { xs:"6vh", sm:"6vh", },
  "&:hover": {
    background: "linear-gradient(90deg, rgb(90, 100, 240), rgb(217, 212, 228))",
    color: "rgba(4, 3, 57, 0.84)",
  },
};

const pageLinkSx = {
  textDecoration: "none",
  fontSize: { xs: "18px", sm: "18px" },
  marginTop: "40px",
  width: { xs: "60px", sm: "30px" },
  height: { xs: "60px", sm: "30px" },
  lineHeight: { xs: "60px", sm: "20px" },
  textAlign: "center",
  borderRadius: { xs: "16px", sm: "5px" },
  transition: "color 0.3s ease",
  display: "flex", // ✅ Forces width & height to apply
  justifyContent: "center", // ✅ Centers text horizontally
  alignItems: "center", // ✅ Centers text vertically
  minWidth: { xs: "40px", sm: "30px" }, // ✅ Ensures width is enforced


};

const GalleryTemplate = ({ slides, pageNumber, totalPages, galleryTitle }) => {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [hoveredPage, setHoveredPage] = useState(null);

  return (
    <Box sx={containerSx}>
      <Typography variant="h2" sx={titleSx}>{galleryTitle}</Typography>

      {/* Image Gallery */}
      <Box sx={galleryGridSx}>
        {slides.map((slide, index) => (
          <Box
            key={index}
            component="img"
            src={slide.src}
            alt={slide.title || `Image ${index + 1}`}
            sx={imageSx}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </Box>

      {/* Lightbox Viewer */}
      {currentIndex >= 0 && (
        <Lightbox
          plugins={[Fullscreen, Zoom, Thumbnails]}
          open={currentIndex >= 0}
          close={() => setCurrentIndex(-1)}
          slides={slides}
          index={currentIndex}
        />
      )}

      {/* Pagination Navigation */}
      {renderNavigation(totalPages, pageNumber, hoveredPage, setHoveredPage)}
    </Box>
  );
};

// **MUI `sx` Styles**
const containerSx = {
  padding: "40px 20px",
  textAlign: "center",
};

const titleSx = {
  fontSize: { xs: "1.8rem", sm: "2rem" }, // Smaller title on mobile
  marginBottom: "20px",
  paddingTop: "15vh",
};

// Responsive Image Grid (One per row on mobile)
const galleryGridSx = {
  display: "grid",
  gap: "15px",
  justifyContent: "center",
  padding: {
    xs: "1ch", // Mobile (1x1)
    sm: "2ch", // Middle Screens (2x2)
    md: "10ch", // Full Screen (3x3)
  },
  gridTemplateColumns: {
    xs: "1fr", // Mobile (1x1)
    sm: "repeat(2, 1fr)", // Middle Screens (2x2)
    md: "repeat(3, 1fr)", // Full Screen (3x3)
  },
  marginBottom:
  {xs:"0vh",
    sm: "5vh",
    md:"-15vh"}
};

const imageSx = {
  width: "100%",
  height: { xs: "auto", sm: "70vh", md: "55vh" }, // Fixed height but keeps aspect ratio
  cursor: "pointer",
  objectFit: "cover",
  borderRadius: "5px",
  transition: "transform 0.2s ease",
  "&:hover": {
    transform: "scale(1.01)",  
  },
};

export default GalleryTemplate;
