import React from "react";
import { Box, Typography } from "@mui/material";
import Background from "../../kusadasi/leaf.jpg";
import Gastric from "../gastric_sleeve/background.png";
import Overview from "./overview.png"
import Hospital from "../gastric_sleeve/hospital.jpg";
import Timeline from "../gastric_sleeve/timeline";
import Doctor from "../gastric_sleeve/doctor.png"
import "@fontsource/allura";
import Slider from "../gastric_sleeve/testimonial"
import About from "../../global/about";
import First from "../../kusadasi/first";
import Header from "../../global/header";
import Second from "../../global/second";


const GastricBypass = () => {

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        backgroundColor: "#f4f4f9",
        color: "#333",
      }}
    >
        <Header  backgroundImage={Background}
            faqImage={Gastric}
            headerText="Gastric Bypass Surgery"
        />
            <About
  title=" About Gastric Bypass Surgery?"
  text="Gastric bypass surgery is designed to reduce the amount of food a patient can consume by shrinking the stomach size and shortening the pathway food travels through the small intestine,
   limiting absorption. In other words, it is both restrictive and malabsorptive surgery. 
   The new digestive system bypasses the lower stomach, the duodenum (the first part of the small intestine), and the first portion of the jejunum (the second part of the small intestine). 
   By reducing the stomach's capacity, this surgery enables weight loss and helps people have a healthier lifestyle, 
  making it a life-changing choice for many individuals."/>

      {/* About Section */}

      <First
      imageSrc={Overview}
      title="How to Work"
      text="Gastric bypass surgery, also known as Roux-en-Y gastric bypass, is performed laparoscopically using small incisions, a camera, and specialized instruments. During the procedure, the surgeon creates a small pouch from the upper part of the stomach and reroutes the small intestine to this pouch. This bypass reduces the stomach’s capacity and limits the amount of food a person can eat, contributing to significant weight loss.

In addition to restricting food intake, gastric bypass surgery alters the digestive process by bypassing a portion of the small intestine. As a result, fewer calories and nutrients are absorbed from the food, which leads to further weight loss. The surgery also impacts hunger-regulating hormones, particularly reducing ghrelin, which decreases appetite. Many patients report feeling full after eating smaller portions, helping them to reduce their overall food intake.

Beyond weight loss, gastric bypass surgery can lead to significant health improvements, especially for patients with type 2 diabetes. Some individuals experience remission from diabetes, with the need for medication disappearing. However, due to the reduced nutrient absorption, lifelong vitamin and mineral supplements are often necessary to avoid deficiencies."
    />

    
       
            
            {/* Benefits and Recommendations Section */}
            <section style={{ backgroundColor: "white", padding: "40px 20px" }}>
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: "20px" }}>
          {/* Benefits Box */}
          <Box sx={{ flex: 1, padding: "20px", borderRadius: "12px", border: "2px solid black", backgroundColor: "white" }}>
            <Typography variant="h4" sx={{ fontWeight: "bold", marginBottom: "16px", color: "#555" }}>
              Benefits of Gastric Bypass Surgery
            </Typography>
            <Typography sx={{ color: "#555", fontSize: "1rem", lineHeight: "1.8" }}>
              <span style={{ color: "rgb(65, 148, 163)" }}>●</span> Significant Weight Loss<br/>
              <span style={{ color: "rgb(65, 148, 163)"}}>●</span> Short Recovery Time<br/>
              <span style={{ color: "rgb(65, 148, 163)" }}>●</span> Increase in Quality of Life<br />
              <span style={{ color: "rgb(65, 148, 163)" }}>●</span> Boosted Energy Levels<br />
              <span style={{ color: "rgb(65, 148, 163)" }}>●</span> Increase in Self-Confidence<br />
              <span style={{ color: "rgb(65, 148, 163)" }}>●</span> Freedom in Movement and Better Joint Health<br />
              <span style={{ color: "rgb(65, 148, 163)"}}>●</span> Healthier Heart.<br />
              <span style={{ color: "rgb(65, 148, 163)" }}>●</span> Relief from Sleep Apnea - Many patients find complete relief from obstructive sleep apnea.<br />
              <span style={{ color: "rgb(65, 148, 163)" }}>●</span> Improvement in Obesity-Related Conditions - Conditions like type 2 diabetes, hypertension,  and hyperlipidemia often improve or resolve.<br />
              <span style={{ color: "rgb(65, 148, 163)" }}>●</span> No Foreign Objects: Unlike gastric banding, this surgery does not involve foreign implants.<br />            
              </Typography>
          </Box>

          {/* Recommendations Box */}
          <Box sx={{ flex: 1, padding: "20px", borderRadius: "12px", border: "2px solid black", backgroundColor: "white" }}>
            <Typography variant="h4" sx={{ fontWeight: "bold", marginBottom: "16px", color: "#555" }}>
              Post-Surgery Recommendations
            </Typography>
            <Typography sx={{ color: "#555", fontSize: "1rem", lineHeight: "1.8" }}>
            There are some important points you should pay attention to when you are discharged after sleeve gastrectomy surgery.<br />
            <br />
              <span style={{ color: "rgb(65, 148, 163)" }}>●</span> Pay particular attention to fluid consumption and avoid carbonated drinks.<br />
              <span style={{ color: "rgb(65, 148, 163)" }}>●</span> Take your medication on time and inform your doctor about any complication.<br />
              <span style={{ color: "rgb(65, 148, 163)" }}>●</span> Engage in short, regular walks but avoid heavy exercise.<br />
              <span style={{ color: "rgb(65, 148, 163)" }}>●</span> Maintain hygiene during post-operative dressings.<br />
              <span style={{ color: "rgb(65, 148, 163)" }}>●</span> Always go to your follow-up appointments on time. <br/>
              <span style={{ color: "rgb(65, 148, 163)" }}>●</span>  Begin vitamin and mineral supplements as advised by your doctor to prevent nutritional deficiencies. <br/>

              <br />
              Taking good care of yourself after surgery period will speed up your recovery process and helps to reach your goals.
            </Typography>
          </Box>
        </Box>
      </section>

      <section style={{ width: "100%", height: "auto", margin: "0", padding: "0" }}>
         <Slider />
      </section>

      <Second
      title="Why Choose Us"
      subtitle="Meet the Doctor"
      text={`Dr. Fikret Beyaz is your dedicated ally in the battle against obesity and type 2 diabetes.
         He is not just a doctor; he's a compassionate healer who understands the physical and emotional challenges you face. With Dr. Beyaz by your side,
          you can look forward to a brighter, healthier future. He's not just a surgeon; he's a pioneer in improving the lives of countless individuals who have struggled with obesity and side effects.
           Like how he always say to their patients: "everybody deserves a healthy life."`}
      image={Doctor}
    />

      <section style={{ backgroundImage: `url(${Hospital})`, backgroundSize: "cover", backgroundPosition: "center", padding: "40px 20px" }}>
      <Box sx={{ display: "flex", flexDirection: "column",    justifyContent: "center", alignItems: "center", gap: "30px" }}>
        {/* First Row - 2 Risks */}
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: "60px" }}>
          {/* Risk 1 */}
          <Box sx={{  maxWidth: "400px",height: "400px", padding: "20px", borderRadius: "12px", border: "white", backgroundColor: "white" }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: "12px", color: "#555" }}>
              Risk 1: Surgery Complications
            </Typography>
            <Typography sx={{ color: "#555", fontSize: "1rem", lineHeight: "1.8" }}>
              Surgery can lead to issues like infection, bleeding, or blood clots.<br/><br/>
              <strong>How we handle this:</strong> We use advanced techniques, prepare patients well, and monitor them closely after surgery.
            </Typography>
          </Box>
          
          {/* Risk 2 */}
          <Box sx={{  maxWidth: "400px", height: "400px", padding: "20px", borderRadius: "12px", border: "white", backgroundColor: "white" }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: "12px", color: "#555" }}>
              Risk 2: Nutritional Deficiencies
            </Typography>
            <Typography sx={{ color: "#555", fontSize: "1rem", lineHeight: "1.8" }}>
              Smaller stomach size may cause vitamin and mineral shortages.<br/><br/>
              <strong>How we handle this:</strong> We offer nutrition advice and supplements, plus regular check-ups to avoid deficiencies.
            </Typography>
          </Box>
        </Box>

        {/* Second Row - 2 Risks */}
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: "60px" }}>
          {/* Risk 3 */}
          <Box sx={{   maxWidth: "400px", height: "400px", padding: "20px", borderRadius: "12px", border: "white", backgroundColor: "white" }}>

            <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: "12px", color: "#555" }}>
              Risk 3: Dumping Syndrome
            </Typography>
            <Typography sx={{ color: "#555", fontSize: "1rem", lineHeight: "1.8" }}>
              Food might move too fast into the small intestine, causing discomfort.<br/><br/>
              <strong>How we handle this:</strong> We guide patients to eat slowly, in small amounts, and avoid sugary foods.
            </Typography>
          </Box>
          
          {/* Risk 4 */}
            <Box sx={{   maxWidth: "400px", height: "400px", padding: "20px", borderRadius: "12px", border: "white", backgroundColor: "white" }}>

            <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: "12px", color: "#555" }}>
              Risk 4: Cosmetic Concerns
            </Typography>
            <Typography sx={{ color: "#555", fontSize: "1rem", lineHeight: "1.8" }}>
              Laparoscopic surgery may leave scars, and weight loss can cause sagging skin.<br/><br/>
              <strong>How we handle this:</strong> We use small incisions for less scarring and provide advice on exercise and skin care. Plastic surgery referrals are available if needed.
            </Typography>
          </Box>
        </Box>

            {/* Third Row - 1 Risk */}
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {/* Risk 5 */}
              <Box sx={{ width: { xs: "85%", md: "85%" }, padding: "20px", borderRadius: "12px", border: "white", backgroundColor: "white" }}>
                <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: "12px", color: "#555" }}>
                  Risk 5: Hair Loss
                </Typography>
                <Typography sx={{ color: "#555", fontSize: "1rem", lineHeight: "1.8" }}>
                  Some patients may lose hair temporarily due to fast weight loss.<br/><br/>
                  <strong>How we handle this:</strong> We recommend proper nutrition and vitamins to support hair health. Hair usually grows back within a few months.
                </Typography>
              </Box>
            </Box>
          </Box>
        </section>
               <Box sx={{ width: "100%", height: "100%", margin: 0, padding: 0 }}>
              <Box sx={{ textAlign: "center", padding:"40px 10px" }}>
                <Box component="h2" sx={{ fontSize: "2.5rem", color: "#555", fontWeight: "bold" }}>
                  Gastric Sleeve Recovery
                </Box>
                <Box
                  component="p"
                  sx={{
                    fontSize: "1.2rem",
                    color: "#555",
                    maxWidth: "800px",
                    margin: "0 auto",
                    lineHeight: "1.6",
                    marginBottom: "80px",
                  }}
                >
                  Recovery phase is almost equally important as surgery to achieve the weight-loss goal. After gastric bypass surgery, patients begin with a liquid diet and gradually progress to clear liquids and then pureed foods. Light physical activity is encouraged throughout recovery.
                  As they transition to regular solid foods, maintaining a focus on protein allows a return to daily activities and exercise with physician approval.
                  Following a recommended diet and exercise routine is essential, and follow-ups help monitor progress.
                  Significant weight loss and routine health evaluations are generally seen one year post-surgery.
                </Box>
                <Box
                  component="p"
                  sx={{
                    fontSize: "0.8rem",
                    color: "#555",
                    marginBottom: "-80px",
                    fontWeight: "bold",
                  }}
                >
                  A step-by-step guide to your post-surgery recovery journey.
                </Box>
              </Box>
            </Box>
      
         <Timeline />



    </div>
  );
};

export default GastricBypass;
