import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ScrollToTop from "./scrollbar.js"; 
import Topbar from "./pages/global/topbar";
import HomePage from "./pages/homepage";
import KusadasiPage from "./pages/kusadasi";
import GastricSleeve from "./pages/operations/gastric_sleeve";
import Bariatric from "./pages/operations/bariatric";
import GastricBypass from "./pages/operations/gastric_bypass";
import Cat from "./pages/global/cat";
import Gallery5 from "./pages/gallery/page5";
import Gallery2 from "./pages/gallery/page2";
import Gallery3 from "./pages/gallery/page3";
import Gallery4 from "./pages/gallery/page4";
import Gallery from "./pages/gallery/index.jsx";
import Test from "./pages/testimonials";
import FAQ from "./pages/faq";
import ContactUs from "./pages/form/email.jsx";
import DoctorPage from "./pages/doctor";
import Hospital from "./pages/hospital/index.jsx";
import PricingPage from "./pages/financing/index.jsx";

// Centralized metadata & structured data for all pages
const pageMetadata = {
  "/kusadasi": {
    title: "Kusadasi | Medical Tourism | Turkey Obesity Clinic",
    description: "Discover Kusadasi, a top destination for medical tourism, offering premium weight loss surgery with beautiful coastal views."
  },
  "/procedures/gastricsleeve": {
    title: "Gastric Sleeve Surgery | Turkey Obesity Clinic",
    description: "Learn about gastric sleeve surgery, a life-changing weight loss procedure performed by expert surgeons at Turkey Obesity Clinic.",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "MedicalProcedure",
      "name": "Gastric Sleeve Surgery",
      "provider": {
        "@type": "MedicalClinic",
        "name": "Turkey Obesity Clinic"
      }
    }
  },
  "/procedures/bariatricsurgery": {
    title: "Bariatric Surgery | Turkey Obesity Clinic",
    description: "Our bariatric surgery solutions help patients achieve sustainable weight loss and a healthier life. Book a consultation today.",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "MedicalProcedure",
      "name": "Bariatric Surgery",
      "provider": {
        "@type": "MedicalClinic",
        "name": "Turkey Obesity Clinic"
      }
    }
  },
  "/procedures/gastricbypass": {
    title: "Gastric Bypass Surgery | Turkey Obesity Clinic",
    description: "Explore gastric bypass surgery, a proven method for long-term weight loss, available at Turkey Obesity Clinic in Kusadasi.",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "MedicalProcedure",
      "name": "Gastric Bypass Surgery",
      "provider": {
        "@type": "MedicalClinic",
        "name": "Turkey Obesity Clinic"
      }
    }
  },
  "/gallery": {
    title: "Gallery | Success Stories | Turkey Obesity Clinic",
    description: "See real-life transformations from our happy patients. Browse our gallery to view weight loss surgery success stories."
  },
  "/gallery/2": {
    title: "Gallery | Success Stories | Turkey Obesity Clinic",
    description: "See real-life transformations from our happy patients. Browse our gallery to view weight loss surgery success stories."
  },
  "/gallery/3": {
    title: "Gallery | Success Stories | Turkey Obesity Clinic",
    description: "See real-life transformations from our happy patients. Browse our gallery to view weight loss surgery success stories."
  },
  "/gallery/4": {
    title: "Gallery | Success Stories | Turkey Obesity Clinic",
    description: "See real-life transformations from our happy patients. Browse our gallery to view weight loss surgery success stories."
  },
  "/gallery/5": {
    title: "Gallery | Success Stories | Turkey Obesity Clinic",
    description: "See real-life transformations from our happy patients. Browse our gallery to view weight loss surgery success stories."
  },


  
  "/about/testimonials": {
    title: "Testimonials | Turkey Obesity Clinic",
    description: "Read reviews and testimonials from satisfied patients who transformed their lives with bariatric surgery at Turkey Obesity Clinic."
  },
  "/about/doctor": {
    title: "Meet Op. Dr. Fikret Beyaz | Turkey Obesity Clinic",
    description: "Meet Op. Dr. Fikret Beyaz, a leading bariatric surgeon specializing in gastric sleeve and bypass surgeries in Turkey."
  },
  "/faq": {
    title: "FAQs | Turkey Obesity Clinic",
    description: "Have questions about weight loss surgery? Read our FAQs to find answers about gastric sleeve, gastric bypass, and more."
  },
  "/contactus": {
    title: "Contact Turkey Obesity Clinic | Book a Consultation",
    description: "Get in touch with our medical team for inquiries, consultations, and appointments for bariatric surgery in Turkey."
  },
  "/about/facility": {
    title: "Our Facility | Turkey Obesity Clinic",
    description: "Explore our state-of-the-art medical facility in Kusadasi, Turkey, designed for patient safety and comfort."
  },
  "/packages": {
    title: "Pricing & Packages | Turkey Obesity Clinic",
    description: "Learn about our affordable weight loss surgery packages, including gastric sleeve and gastric bypass procedures in Turkey."
  }
};

function App() {
  const location = useLocation(); // Get current route
  const metadata = pageMetadata[location.pathname] || {
    title: "Turkey Obesity Clinic | Expert Bariatric Surgery in Kusadasi, Turkey",
    description: "Turkey Obesity Clinic provides world-class bariatric surgery and weight loss solutions in Kusadasi, Turkey.  The clinic offers personalized care with experienced surgeons, advanced medical facilities, and comprehensive post-surgery support for long-term success."
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        {metadata.structuredData && (
          <script type="application/ld+json">
            {JSON.stringify(metadata.structuredData)}
          </script>
        )}
      </Helmet>

      <ScrollToTop />
      <Topbar />

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/kusadasi" element={<KusadasiPage />} />
        <Route path="/procedures/gastricsleeve" element={<GastricSleeve />} />
        <Route path="/procedures/bariatricsurgery" element={<Bariatric />} />
        <Route path="/procedures/gastricbypass" element={<GastricBypass />} />
        <Route path="/gallery/5" element={<Gallery5 />} />
        <Route path="/gallery/2" element={<Gallery4 />} />
        <Route path="/gallery/3" element={<Gallery3 />} />
        <Route path="/gallery/4" element={<Gallery2 />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/about/testimonials" element={<Test />} />
        <Route path="/about/doctor" element={<DoctorPage />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/about/facility" element={<Hospital />} />
        <Route path="/packages" element={<PricingPage />} />
      </Routes>

      <Cat />
    </HelmetProvider>
  );
}

export default App;

