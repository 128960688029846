
import First from "./images/37.jpeg"
import Second from "./images/38.jpeg"
import Third from "./images/39.jpeg"
import Fourth from "./images/40.jpeg"
import Fifth from "./images/41.jpeg"
import Sixth from "./images/42.jpeg"
import Seventh from "./images/43.jpeg"
import Eight from "./images/44.jpeg"
import Nine from "./images/45.jpeg"
import GalleryTemplate from "./gallerytemplate";



export const slides = [
    {
      src:  Nine,
      title: 'Image 1',
      description: 'This is the first image.',
    },
    {
      src: First,
      title: 'Image 2',
      description: 'This is the second image.',
    },
    {
      src: Third,
      title: 'Image 3',
      description: 'This is the third image.',
    },
    {
      src:  Second,
      title: 'Image 4',
      description: 'This is the first image.',
    },
    {
      src:  Fifth,
      title: 'Image 5',
      description: 'This is the first image.',
    },
    {
      src: Sixth,
      title: 'Image 6',
      description: 'This is the first image.',
    },
    {
      src:  Seventh,
      title: 'Image 7',
      description: 'This is the first image.',
    },
    {
      src:  Fourth,
      title: 'Image 8',
      description: 'This is the first image.',
    },
    {
      src:  Eight,
      title: 'Image 9',
      description: 'This is the first image.',
    }
  ];

  function Gallery() {


    return (
      <GalleryTemplate
        slides={slides}
        pageNumber={1}  // Current Page
        totalPages={5}  // Total Number of Pages
        galleryTitle="Before & After Gallery"
      />
    );
  
  
  }
  export default Gallery;