import React, { useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";

function BMICalculator() {
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [bmi, setBmi] = useState(null);
  const [category, setCategory] = useState("");

  const calculateBMI = () => {
    if (height && weight) {
      const heightInMeters = height / 100;
      const bmi = (weight / (heightInMeters * heightInMeters)).toFixed(2);
      setBmi(bmi);

      if (bmi < 18.5) {
        setCategory("Underweight – less than 18.5");
      } else if (bmi >= 18.5 && bmi <= 24.9) {
        setCategory("Normal Weight – 18.5-24.9");
      } else if (bmi >= 25 && bmi <= 29.9) {
        setCategory("Overweight – 25.0-29.9");
      } else if (bmi >= 30 && bmi <= 34.9) {
        setCategory("Obesity (Class 1) – 30.0-34.9");
      } else if (bmi >= 35 && bmi <= 39.9) {
        setCategory("Severe Obesity (Class 2) – 35.0-39.9");
      } else if (bmi >= 40 && bmi <= 49.9) {
        setCategory("Morbid Obesity (Class 3) – 40.0-49.9");
      } else {
        setCategory("Super-obesity/Extreme Obesity – greater than 50.0");
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" }, // ✅ Stacks on mobile, side-by-side on larger screens
        alignItems: "center",
        justifyContent: "center",
        height: { xs: "auto", md: "90vh" },
        background: "linear-gradient(135deg,rgb(21, 218, 224) 10%, #5151e5 100%)",
        padding: { xs: "3ch", md: "50px" }, // ✅ Padding for different screen sizes
        gap: { xs: "20px", md: "50px" }, // ✅ Adds space between sections
      }}
    >
      {/* BMI Form Section */}
      <Box
       sx={{
        background: "rgba(255, 255, 255, 0.2)",
        borderRadius: "20px",
        padding: { xs: "20px", md: "30px" }, // ✅ Smaller padding on mobile
        boxShadow: "0 8px 32px rgba(31, 38, 135, 0.37)",
        backdropFilter: "blur(10px)",
        border: "1px solid rgba(255, 255, 255, 0.18)",
        textAlign: "center",
        maxWidth: { xs: "90%", sm: "80%", md: "400px" },
        width: "100%",
        color: "#fff",
        margin: "auto", // ✅ Centers on mobile
      }}
      >
        <Typography variant="h4" sx={{ fontSize: "2rem", marginBottom: "20px" }}>
          BMI Calculator
        </Typography>

        {/* Weight Input */}
        <TextField
          fullWidth
          type="number"
          label="Weight (kg)"
          variant="outlined"
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
          sx={inputStyles}
        />

        {/* Height Input */}
        <TextField
          fullWidth
          type="number"
          label="Height (cm)"
          variant="outlined"
          value={height}
          onChange={(e) => setHeight(e.target.value)}
          sx={{ ...inputStyles, marginTop: "15px" }}
        />

        {/* Calculate Button */}
        <Button
          fullWidth
          onClick={calculateBMI}
          sx={{
            marginTop: "20px",
            background: "linear-gradient(90deg,rgb(66, 73, 207),rgb(43, 12, 120))",
            color: "white",
            borderRadius: "30px",
            fontSize: "1rem",
            padding: "10px",
            cursor: "pointer",
            transition: "background 0.3s ease",
            "&:hover": { background: "rgb(0, 48, 86)" },
          }}
        >
          Calculate BMI
        </Button>
      </Box>

      {/* BMI Results Section */}
      {bmi && (
        <Box
          sx={{
            color: "#fff",
            maxWidth: "500px",
            width: "100%",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography variant="h5" sx={{ marginTop: "30px" }}>
            Your BMI: {bmi}
          </Typography>
          <Typography variant="h6" sx={{ color: "rgb(16, 6, 55)", marginTop: "10px" }}>
            Category: {category}
          </Typography>

          <Typography sx={{ fontSize: "1rem", color: "#f0f0f0", marginTop: "20px" }}>
            The following are general weight categories based on BMI:
          </Typography>

          <ul style={{ padding: "0 20px", textAlign: "left", fontSize: "1rem", color: "#f0f0f0" }}>
            <li>Underweight – less than 18.5</li>
            <li>Normal Weight – 18.5-24.9</li>
            <li>Overweight – 25.0-29.9</li>
            <li>Obesity (Class 1) – 30.0-34.9</li>
            <li>Severe Obesity (Class 2) – 35.0-39.9</li>
            <li>Morbid Obesity (Class 3) – 40.0-49.9</li>
            <li>Super-obesity/Extreme Obesity – greater than 50.0</li>
          </ul>
        </Box>
      )}
    </Box>
  );
}

const inputStyles = {
  background: "rgba(255, 255, 255, 0.3)",
  borderRadius: "10px",
  fontSize: "1rem",
  color: "#fff",
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderColor: "white" },
    "&:hover fieldset": { borderColor: "blue" },
    "&.Mui-focused fieldset": { borderColor: "white" },
  },
  "& .MuiInputLabel-root": { color: "rgb(2, 2, 83)" },
  input: { color: "rgb(0, 48, 86)" },
};

export default BMICalculator;

