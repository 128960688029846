import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCarousel,
  MDBCarouselItem,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";
import { Box, useMediaQuery, useTheme, Button} from "@mui/material";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';  // For icons

export default function Slider() {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md")); // Detect small screens

    return (
      <MDBContainer fluid style={{
        padding: "auto",
        background: `radial-gradient(circle at 50% 50%, #00ffcc 0%, #0066ff 100%)`,
        backgroundBlendMode: "screen",
        height: "90%"

      }}>
        <style>
          {`
            .custom-title {
              font-size: 1rem !important;
              font-weight: bold;
              color: #ffffff;
              text-align: center;
              margin-top: 0.5rem;
            }
            .custom-text {
              font-size: 1.2rem !important;
              font-style: italic;
              color: black;
              text-align: center;
              padding-top: 2.5rem;
            }
            .mdb-carousel-control-prev-icon,
            .mdb-carousel-control-next-icon {
              opacity: 1. !important;  /* Fully visible */
              filter: brightness(0,4) !important; /* Make it more visible */
}

          `}
        </style>
        <MDBRow className="d-flex justify-content-center">
          <MDBCol md="12">
            <div style={{ textAlign: "center", marginBottom: "0.5rem", paddingBottom: "0.5rem" }}>
              <MDBIcon fas icon="quote-left" size="3x" style={{ color: "white",paddingTop: "2rem" }} />
            </div>
            <MDBCard style={{ backgroundColor: "transparent", border: "none" }}>
              <MDBCardBody style={{ padding: "2rem" }}>
                <MDBCarousel touch	keyboard >
                  {[
                    {
                      name: "Elizabeth Taylor",
                      text: "Thank you so much Dr Fikret for giving me the best experience whilst I was in Turkey for my gastric sleeve. Your bed side manner is second to none and you made me feel so relaxed and cared for. I will never be able to thank you enough. YOU ARE THE BEST 😁"

                    },
                    {
                      name: "Carol McDonald",
                      text: "I had my operation over two years ago and can honestly say the care I received from Dr Fikret and all the staff was definitely 5 Star. I had a problem with my blood pressure being high and Dr Fikret visited regularly, always with a big smile on his face..."

                    },
                    {
                      name: "Heer Wahab",
                      textSmall: "<br />Best Doctor in the whole wide world.....Bless him. He is the most humblest person I have met.<br /><br /><br />",
                      textLarge: "<br />Best Doctor in the whole wide world.....Bless him. He is the most humblest person I have met.<br /><br />"



                    }
                  ].map((item, index) => (
                    <MDBCarouselItem itemId={index + 1} key={index}>
                      <MDBRow className="d-flex justify-content-center">
                        <MDBCol lg="10" xl="8">
                          <MDBRow>
                            <MDBCol md="9" lg="7" xl="8" style={{ textAlign: "center", textAlignLast: "left", margin: "0 auto" }}>
                            <p 
                            className="custom-text"
                            dangerouslySetInnerHTML={{
                              __html: item.name === "Heer Wahab"
                                ? isSmallScreen
                                  ? item.textSmall
                                  : item.textLarge
                                : item.text
                            }} 
                          />

                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>
                  ))} 
                </MDBCarousel>
              </MDBCardBody>
            </MDBCard>
            <div style={{ textAlign: "center", marginTop: "1rem", paddingTop: "0.5rem", color: "#fff" }}>
              <MDBIcon fas icon="quote-right" size="3x" style={{ color: "white" }} />
            </div>
            <Box
  sx={{
    display: "flex",
    justifyContent: { xs: "center", md: "right" }, // ✅ Center on mobile, left on desktop
    alignItems: "center",
    width: "100%",
    padding: { xs: "20px", md: "20px" }, // ✅ Add padding on desktop for better spacing
  }}
>
            <Button
              href="/about/testimonials"
              target="_blank"
              rel="dofollow"
              sx={{
                marginTop: "0px",
                backgroundColor: "white",
                color: "#0066ff",
                fontWeight: "bold",
                width:{xs:"95%", md:"auto"},
                borderRadius: "50px",
                padding: "10px 20px",
                fontSize: "0.9rem",
                transition: "background-color 0.3s ease, transform 0.2s ease",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)"
              }}
              onMouseOver={(e) => { e.target.style.backgroundColor = "#0066ff"; e.target.style.color = "white"; e.target.style.transform = "scale(1.05)"; }}
              onMouseOut={(e) => { e.target.style.backgroundColor = "white"; e.target.style.color = "#0066ff"; e.target.style.transform = "scale(1)"; }}
            >
              Read More Testimonials →
            </Button>
            </Box>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }

  
/*
export default function Slider() {
  return (
    <MDBContainer fluid style={{
      padding: "2rem 0",
      background: `radial-gradient(50% 123.47% at 50% 50%, #00ff94 0%, #720059 100%),
                  linear-gradient(121.28deg, #669600 0%, #ff0000 100%),
                  linear-gradient(360deg, #0029ff 0%, #8fff00 100%),
                  radial-gradient(100% 164.72% at 100% 100%, #6100ff 0%, #00ff57 100%),
                  radial-gradient(100% 148.07% at 0% 0%, #fff500 0%, #51d500 100%)`,
      backgroundBlendMode: "screen, color-dodge, overlay, difference, normal"
    }}>
      <MDBRow className="d-flex justify-content-center">
        <MDBCol md="12">
          <div style={{ textAlign: "center", marginBottom: "1rem", paddingBottom: "0.5rem" }}>
            <MDBIcon fas icon="quote-left" size="3x" style={{ color: "white" }} />
          </div>
          <MDBCard>
            <MDBCardBody style={{ padding: "2rem" }}>
              <MDBCarousel showIndicators showControls dark>
                {[
                  {
                    img: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp",
                    name: "Maria Smantha - Web Developer",
                    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquam amet animi blanditiis consequatur debitis dicta distinctio."
                  },
                  {
                    img: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(2).webp",
                    name: "Lisa Cudrow - Graphic Designer",
                    text: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
                  },
                  {
                    img: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(3).webp",
                    name: "John Smith - Marketing Specialist",
                    text: "At vero eos et accusamus et iusto odio dignissimos qui blanditiis praesentium voluptatum deleniti."
                  }
                ].map((item, index) => (
                  <MDBCarouselItem itemId={index + 1} key={index}>
                    <MDBRow className="d-flex justify-content-center">
                      <MDBCol lg="10" xl="8">
                        <MDBRow>
                          <MDBCol lg="4" style={{ display: "flex", justifyContent: "center" }}>
                            <img
                              src={item.img}
                              className="rounded-circle shadow-1 mb-4 mb-lg-0"
                              alt="avatar"
                              width="150"
                              height="150"
                            />
                          </MDBCol>
                          <MDBCol md="9" lg="7" xl="8" style={{ textAlign: "center", textAlignLast: "left", margin: "0 auto" }}>
                            <h4 style={{ marginBottom: "1rem" }}>{item.name}</h4>
                            <p style={{ marginBottom: "0", paddingBottom: "1rem" }}>{item.text}</p>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </MDBCarouselItem>
                ))}
              </MDBCarousel>
            </MDBCardBody>
          </MDBCard>
          <div style={{ textAlign: "center", marginTop: "1rem", paddingTop: "0.5rem" }}>
            <MDBIcon fas icon="quote-right" size="3x" style={{ color: "white" }} />
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
*/