import "@fontsource/allura";
import React from "react";
import { Typography, Grid, Paper, List, ListItem, ListItemIcon, ListItemText,Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Background from "../kusadasi/leaf.jpg";
import Gastric from "./doctor.JPG";
import Overview from "./phil.JPG"
import Doctor from "../operations/gastric_sleeve/doctor.png"
import Doctor2 from "./mac.JPG"
import Header from "../global/header";
import About from "../global/about";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import Deli from "./bora.jpg"
import { useMediaQuery } from "@mui/material";

const DoctorPage = () => {
  const isTablet = useMediaQuery("(max-width:960px)"); // sm (small screens)



  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        backgroundColor: "#f4f4f9",
        color: "#333",
      }}
    >
      {/* Hero Section */}
      <Header
      backgroundImage={Background}
      faqImage={isTablet ? Gastric : Doctor2} 
      headerText="Op. Dr. Fikret Beyaz"
    />
          <About
  title="Meet Your Surgeon"
  text="Dr. Fikret Beyaz is the bariatric surgeon at the Gozde Kusadasi Hospital, where he leads his multidisciplinary team to help patients. 
  He has dedicated a significant part of his surgical career to the surgical treatment of obesity and Type 2 Diabetes, 
  desiring to make these procedures less painful through minimally invasive techniques. He performed over 20,000 bariatric surgeries with a high satisfaction rate. 
  Dr. Fikret Beyaz continues to provide his services with a fully equipped team, 
  adhering to the principles of a professional medical approach, expert surgical experience, and a compassionate treatment process.
"
/>

<Box
      component="section"
      sx={{
        backgroundColor: "rgb(234, 244, 246)",
        minHeight: "75vh", // ✅ Prevents content from being cut off
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: { xs: "20px", sm: "40px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // ✅ Stacks on mobile, side-by-side on desktop
          alignItems: "center",
         //maxWidth: "1200px",
          width: "100%",
          gap: { xs: "20px", md: "40px" },
        }}
      >
        {/* Left Side - Image */}
        <Box
          component="img"
          src={Overview}
          alt="Health Tourism in Kuşadası"
          sx={{
            width: { xs: "100%", md: "50%" }, // ✅ Full width on mobile, 40% on desktop
            height: "auto",
            borderRadius: "8px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            marginBottom: { xs: "16px", md: "0" }, // ✅ Spacing for mobile
          }}
        />

        {/* Right Side - Text */}
        <Box
          sx={{
            width: { xs: "100%", md: "60%" },
            textAlign: "left",
          }}
        >
          {/* Title */}
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              color: "#195D8A",
              fontSize: { xs: "1.8rem", sm: "2rem" }, // ✅ Adjusted for mobile readability
              textAlign: { xs: "center", md: "left" },
            }}
          >
            Our Philosophy
          </Typography>

          {/* Body Text */}
          <Typography
            sx={{
              color: "rgba(3, 0, 9, 0.67)",
              fontSize: { xs: "1rem", sm: "1.2rem" }, // ✅ Smaller text for mobile readability
              lineHeight: "1.8",
              marginTop: "16px",
              textAlign: { xs: "justify", md: "left" },
            }}
          >
            Losing weight in a healthy way is a complex and challenging process for many people.
            Following a diet or exercise plan is insufficient or unsuitable for everyone.
            Those who attempt to lose weight understand the difficulties of the process more than anyone else.
            They need support, care, and a positive push to succeed.
          </Typography>

          <Typography
            sx={{
              color: "rgba(3, 0, 9, 0.67)",
              fontSize: { xs: "1rem", sm: "1.2rem" },
              lineHeight: "1.8",
              marginTop: "32px",
              marginBottom: { xs: "40px", md: "70px" }, // ✅ Smaller margin on mobile
            }}
          >
            Dr. Fikret Beyaz is your dedicated ally in the battle against obesity and type 2 diabetes.
            He is not just a doctor; he's a compassionate healer who understands the physical and emotional challenges you face.
            With Dr. Beyaz by your side, you can look forward to a brighter, healthier future.
            Like how he always says to his patients:{" "}
            <strong>
              <i>"Everybody deserves a healthy life."</i>
            </strong>
          </Typography>
        </Box>
      </Box>
    </Box>
      <section
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        padding: "40px",
        borderRadius: "12px",
        margin: "auto",
        background: "rgb(3, 135, 168)", 
        color: "white",
        boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)",
      }}
    >


      {/* Quote Icon */}
      <FormatQuoteIcon style={{marginTop: "10px", fontSize: "4rem", color: "rgba(255, 255, 255, 0.8)" }} />
     

      {/* Quote Text */}
   
      <Typography variant="h5"
        sx={{
          fontStyle: "italic",
          marginTop: "10px",
          fontSize: { xs: "1rem", sm: "1.5rem", md: "1.8rem" }, // ✅ Responsive font sizes
          lineHeight: "1.6",
        }}>
      I have committed my life to helping people regain their health and confidence.
       Every patient who walks through this door is not just a case but a person with a story, a struggle, and a hope for a better future. 
       I understand the fears, the doubts, and the questions that come with the decision to undergo surgery. That is why I take the time to listen, guide, and support, not just in the operating room but throughout the entire journey.
        My goal is not only to perform a procedure but to give people a chance to start a new chapter in their lives, free from the limitations that once held them back. Health is not just about numbers or appearances;
         it is about living fully, feeling strong, and confidently embracing every day. 

      </Typography>


      {/* Author (Optional) */}
      <Box sx={{ textAlign: { xs: "center", sm: "right" }, width: "100%" }}>
  <Typography sx={{ marginTop: "20px", opacity: 0.8, fontSize: { xs: "1.8rem", sm: "2.5rem" } }}>
    Dr. Fikret Beyaz
  </Typography>
</Box>

    </section>
            
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: { xs: "column-reverse", md: "row" }, // ✅ Image first on mobile, right on desktop
        alignItems: "center",
        width: "100%",
        height: "auto", // ✅ Adjusts height dynamically
        padding: "0",
        margin: "0",
      }}
    >
      {/* Left Side - Text */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: { xs: "20px", md: "40px" }, // ✅ Smaller padding on mobile
          backgroundColor: "#f0f8ff",
          textAlign: { xs: "center", md: "left" }, // ✅ Center on mobile, left on desktop
        }}
      >
        {/* Title */}
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            color: "#195D8A",
            textAlign: { xs: "center", sm: "center", md:"left"  }, // ✅ Consistently center on mobile
            fontSize: { xs: "2rem", md: "2.5rem" },
            width: "100%", // ✅ Ensures alignment applies correctly
            // ✅ Adjusted font sizes for different screens
          }}
        >
          Meet the Doctor
        </Typography>

        {/* Subtitle */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: "medium",
            textAlign:{xs:"center", sm:"left"},
            marginTop: "40px",
            color: "#333",
            fontSize: { xs: "1.2rem", md: "1.5rem" },
            width: "100%", // ✅ Ensures alignment applies correctly

          }}
        >
          Education and Background
        </Typography>

        {/* Underline */}
        <Box
          sx={{
            height: "3px",
            width: { xs: "100%", md: "48%" },
            textAlign: "right", // ✅ Full width on mobile, fixed on larger screens
            backgroundColor: "rgb(25, 93, 138)",
            marginLeft:{ xs: "0", md: "-6vh" },
            marginBottom: "0.5vh"            
          }}
        />

        {/* Description */}
        <Typography
          sx={{
            color: "#555",
            fontSize: { xs: "1rem", md: "1.2rem" },
            lineHeight: "1.8",
            padding: { xs: "10px", md: "40px" }, // ✅ Less padding on mobile
          }}
        >
          Born on December 12, 1977, in Hatay,  
          Dr. Fikret Beyaz embarked on his medical journey at&nbsp;  
          <a
            href="https://ege.edu.tr/eng-0/Homepage.html"
            target="_blank"
            rel="nofollow"
            style={{ color: "#007BFF", textDecoration: "none" }}
          >
            Ege University Faculty of Medicine
          </a>{" "}
          in 1996.  
          His passion for healing led him to specialize in General Surgery at&nbsp;
          <a
            href="https://www.mku.edu.tr/default.aspx"
            target="_blank"
            rel="nofollow"
            style={{ color: "#007BFF", textDecoration: "none" }}
          >
            Hatay Mustafa Kemal University
          </a>{" "}
          in 2004.  

          He has dedicated years of his life to different hospitals, including&nbsp;
          Elbistan State Hospital, Adana Çukurova Dr. Aşkım Tüfekçi State Hospital, and&nbsp;
          Adana Çukurova State Hospital—all while advancing his expertise in the treatment of obesity and type 2 diabetes.  

          Dr. Beyaz's quest for excellence has taken him worldwide to attend specialized courses and programs in metabolic surgery.  
          He continues to pursue his passion for expertise by receiving certificates and recognition from prestigious institutions,  
          including the&nbsp;
          <a
            href="https://www.linkedin.com/in/dr-robert-rutledge-49610712/"
            target="_blank"
            rel="nofollow"
            style={{ color: "#007BFF", textDecoration: "none" }}
          >
            Surgeon of Excellence Certification by Dr. Robert Rutledge
          </a>.
        </Typography>
      </Box>

      {/* Right Side - Image */}
      <Box
        component="img"
        src={Doctor} // ✅ Replace with actual image path
        alt="Doctor"
        sx={{
          flex: 1,
          width: { xs: "100%", md: "50%" }, // ✅ Full width on mobile, 50% on desktop
          height: { xs: "50vh", md: "auto" }, // ✅ Auto height on mobile, full height on desktop
          objectFit: "cover",
          borderRadius: "4px",
        }}
      />
    </Box>
    <Box
  component="section"
  sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    backgroundImage: `url(${Deli})`,
    color: "white",
    padding: {xs:"2ch", md:"50px"},
  }}
>
      <Paper
        elevation={6}
         sx={{
    display: "flex",
    width: {xs:"100%",md:"80%"},
    maxWidth: "1200px",
    borderRadius: "12px",
    overflow: "hidden",
    padding: {xs:"20px",md:"40px"},
    backgroundColor: "rgba(255, 255, 255, 0.1)", // ✅ Use `backgroundColor` instead of `background`
    backdropFilter: "blur(15px)",
    color: "white",
  }}
      >
        <Grid container spacing={4}>
          {/* International Certifications Section */}
          <Grid item xs={12} md={6}>
            <Typography variant="h5" fontWeight="bold" gutterBottom>
              International Certifications
            </Typography>
            <List>
              {["Certificate of Participation Surgeon of Excellence Bronze Certification, Dr. Robert Rutledge, Adana, 2018",
                "Clinical Immersion Bariatric Revision (Obesity Revision Surgery), Dr. Bruno Dillemans, Belgium, 2016",
                "Certificate of Completion Bariatric Surgery Master Class, Istanbul, 2015",
                "I. Medtronic Advanced Bariatric Summit, Istanbul, 2020",
                "II. Bariatric and Metabolic Surgery Symposium: Innovation in Bariatric Surgery, Istanbul, 2019"].map((cert, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <CheckCircleIcon style={{ color: "#FFD700" }} />
                    </ListItemIcon>
                    <ListItemText primary={cert} />
                  </ListItem>
                ))}
            </List>
          </Grid>

          {/* Affiliations Section */}
          <Grid item xs={12} md={6}>
            <Typography variant="h5" fontWeight="bold" gutterBottom>
              Affiliations
            </Typography>
            <List>
              {["Izmir Medical Chamber",
                "Turkish Surgical Association",
                "Endoscopic Laparoscopic Surgery Association",
                "Turkish Obesity Surgery Association"].map((affiliation, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <CheckCircleIcon style={{ color: "#FFD700" }} />
                    </ListItemIcon>
                    <ListItemText primary={affiliation} />
                  </ListItem>
                ))}
            </List>
          </Grid>
        </Grid>
      </Paper>
    </Box>               
    <Box
  component="section"
  sx={{
    backgroundColor: "white",
    padding: { xs: "20px", sm: "40px", md: "50px 200px" }, // ✅ Adjust padding for different screen sizes
  }}
>
            <Typography variant="h4" sx={{ fontWeight: "bold", marginBottom: "16px", color: "#555" }}>
              Patient's Own Words About Dr. Fikret Beyaz
            </Typography>
            <Typography sx={{ color: "#555", fontSize: {sx:"0.8rem", md:"1rem"}, lineHeight: "1.8", marginBottom: "20px",fontStyle: "italic" }}>
           " Myself and my daughter Dannielle, travelled to Turkey from the Uk to have a gastric sleeve performed by Dr Fikret on the 22nd March 2021 at Gozde Hospital.
            From the moment we arrived, we were looked after extremely well by the Getslim team and the lovely nurses.
             My daughter was very nervous and cried prior to the operation and Dr Fikret, spent time talking to her and putting her mind at ease.
              He gave her a hug and said she was in very good hands. He didn’t want her waiting and hanging around so he made sure she was one of the first ones done on that day. 
              He popped back to see us both the following day to check on us to see how we were both doing and again the day before we left. 
              Our scars are very neat and we’ve had no problems with any of our surgery. I’m really pleased that Dr Fikret uses a drain as this is an added bonus, another check regarding leaks.
               What I really like is that we can follow Dr Fikret on Instagram and he’s always available to have a chat or ask a question if needed.
                The tests and checks he gets done on you are simply the best and you would never get all this in the UK, either private or the NHS. 
                I would highly recommend anyone that is thinking of having this done, is to go to Dr Fikret. Thank you so much from Michelle and Dannielle Cook "

         
            </Typography>
            <Typography variant="h4" sx={{  fontWeight: "bold",fontSize: "1.4rem",marginTop: "32px", color: "#555" }}>
            If you are considering bariatric surgery and have any questions or hesitations, 
            Dr. Fikret Beyaz would be happy to answer them and clear your hesitations. 
            We are one{" "}
            
            <a
            href="./contactus"
            target="_blank"
            rel="dofollow"
            style={{ color: "#007BFF", textDecoration: "none" }}
          >
            e-mail
        </a>
        {" "}away from you.
            </Typography>

      </Box>
   
    </div>
  );
};

export default DoctorPage;
