/*
import React from "react";
import { IconButton,Box, Button, TextField, Typography, Paper, Grid } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik } from "formik";
import * as yup from "yup";
import emailjs from "emailjs-com";
import Kitten from "./pamukadam.jpg"
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import LinkedInIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import Telephone from "@mui/icons-material/Phone";
import Mail from "@mui/icons-material/Email";


const ContactUs = () => {
  const handleFormSubmit = (values, { setSubmitting, resetForm }) => {
    emailjs
      .send("service_1erktdr", "template_dkjlssh", values, "svMpo78Iul0rfj1bm")
      .then(
        () => {
          toast.success("Form Submitted Successfully");
          resetForm();
        },
        () => {
          toast.error("Error submitting form");
        }
      );
    setSubmitting(false);
  };

  return (
    <section
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundImage: `url(${Kitten})`, // Replace with your image
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingBottom: "80px",
        marginBottom: "2px",
        marginTop:"20px"
      }}
    >
      <ToastContainer position="top-center" />
      <Paper
        elevation={6}
        style={{
          display: "flex",
          width: "100%",
          maxWidth: "1000px",
          height: "80%",
          borderRadius: "12px",
          background: "transparent", // Allow transparency
          marginTop: "100px",
          backdropFilter: "blur(15px)", // Adds blur effect
          boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)", // Adds soft shadow
      
        }}
      >
     
        <div
          style={{
            width: "40%", // Reduced width to allow background visibility
            backgroundColor: "rgba(23, 23, 26, 0.85)",
            color: "white",
            display: "flex",
            flexDirection: "column",
            padding: "40px",
            margin: "10px", // Added margin to create space around
            borderRadius: "10px", // Optional: Rounds the corners for better visuals
            
          }}
        >
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "16px" }}>
            Have any questions or do you want to have more information about the surgery, Kusadasi or anything else? 
            We are only one email or phone call away from you.

          </Typography>
          <Box sx={{ cursor: "pointer", marginTop: "50px" }} onClick={() => window.open("https://www.google.com/maps?q=Gözde+Kuşadası+Hastanesi", "_blank")}> 
                <Typography
                variant="body1"
                sx={{
                color: "white",
                whiteSpace: "pre-line",
                "&:hover": {
                color: "#007BFF",
                },
                }}
                >
                 <RoomOutlinedIcon fontSize="small" />   
                 {"    "}Türkmen,{"\n"}
                  Rıza Saraç Cd.,{"\n"}
                  09400 Kuşadası/Aydın,{"\n"}
                  Türkiye
                </Typography>
              </Box>


          <Typography variant="body1">   <Telephone fontSize="small" />    <a href="tel://+905465497787" style={{ color: "white" }}>+90 546 549 77 87 </a></Typography>
          <Typography variant="body1">   <Mail fontSize="small" />    <a href="mailto:info@pem-health.com" style={{ color: "white" }}>info@pem-health.com</a></Typography>
          <Box sx={{ display: "flex", gap: "15px",marginTop: "10px" }}>
          <IconButton
            component="a"
            href="https://www.facebook.com/Op.Dr.FikretBeyaz/"
            target="_blank"
            sx={{ color: "#0e76a8" }}
          >
            <LinkedInIcon fontSize="large" />
          </IconButton>


          <IconButton
            component="a"
            href= "https://www.instagram.com/dr.fikretbeyaz/?hl=en"
            target="_blank"
            sx={{ color: "#E1306C" }}
          >
            <InstagramIcon fontSize="large" />
          </IconButton>
        </Box>
        </div>

        <div
  style={{
    width: "75%",
    padding: "40px",
    background: "transparent", // Makes it slightly transparent
    backdropFilter: "blur(15px)", // Adds blur effect
    borderRadius: "12px", // Optional: rounded corners for a smooth look
    boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)", // Adds soft shadow
  }}
>


          <Formik initialValues={initialValues} validationSchema={contactSchema} onSubmit={handleFormSubmit}>
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField fullWidth variant="outlined" label="First Name" name="firstName" onBlur={handleBlur} onChange={handleChange} value={values.firstName} error={!!touched.firstName && !!errors.firstName} helperText={touched.firstName && errors.firstName}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "white", // Default outline color
                          },
                          "&:hover fieldset": {
                            borderColor: "green", // Outline color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "white", // Outline color when focused
                          },
                         
                      
      
                 
                        },
                        "& .MuiInputLabel-root": {
                            color: "rgb(2, 2, 83)", // Changes the input text color
                          },
                      }}
                    
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField fullWidth variant="outlined" label="Last Name" name="lastName" onBlur={handleBlur} onChange={handleChange} value={values.lastName} error={!!touched.lastName && !!errors.lastName} helperText={touched.lastName && errors.lastName}
                     sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "white", // Default outline color
                          },
                          "&:hover fieldset": {
                            borderColor: "green", // Outline color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "white", // Outline color when focused
                          },
                          
                      
                        },
                        "& .MuiInputLabel-root": {
                            color: "rgb(2, 2, 83)", // Changes the input text color
                          },

                      }} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth variant="outlined" label="Email" name="email" onBlur={handleBlur} onChange={handleChange} value={values.email} error={!!touched.email && !!errors.email} helperText={touched.email && errors.email}
                     sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "white", // Default outline color
                          },
                          "&:hover fieldset": {
                            borderColor: "green", // Outline color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "white", // Outline color when focused
                          },
                          
                      
                        },
                        "& .MuiInputLabel-root": {
                            color: "rgb(2, 2, 83)", // Changes the input text color
                          },

                      }} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth variant="outlined" label="Who referred you?" name="referredBy" onBlur={handleBlur} onChange={handleChange} value={values.referredBy} 
                     sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "white", // Default outline color
                          },
                          "&:hover fieldset": {
                            borderColor: "green", // Outline color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "white", // Outline color when focused
                          },
                        
                      
                        },
                        "& .MuiInputLabel-root": {
                            color: "rgb(2, 2, 83)", // Changes the input text color
                          },

                      }}/>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth multiline rows={4} variant="outlined" label="Message" name="message" onBlur={handleBlur} onChange={handleChange} value={values.message} error={!!touched.message && !!errors.message} helperText={touched.message && errors.message}
                     sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "white", // Default outline color
                          },
                          "&:hover fieldset": {
                            borderColor: "green", // Outline color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "white", // Outline color when focused
                          },
                     
                        },
                        "& .MuiInputLabel-root": {
                            color: "rgb(2, 2, 83)", // Changes the input text color
                          },

                      }} />
                  </Grid>
                  <Grid item xs={12}>
                  <div style={{ display: "flex", justifyContent: "center" }}>

                    <Button type="submit" variant="contained" color="primary" fullWidth style={{
                marginTop: "10px",
                marginLeft: "10px",
                padding: "8px 120px",
                width: "80px",
                backgroundColor: "rgba(222, 207, 255, 0.54)",
                justifyContent: "center",
                textAlign: "center",
                color: "black",
                borderRadius: "50px",
                fontSize: "1rem",
                cursor: "pointer",
                transition: "background-color 0.3s, color 0.3s, transform 0.3s ease",
              }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#555";
                e.target.style.color = "white";
                e.target.style.transform = "scale(1.05)";
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = "rgba(222, 207, 255, 0.54)";
                e.target.style.color = "black";
                e.target.style.transform = "scale(1)";
              }}
              
            >
              Submit
</Button>
</div>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
      </Paper>
    </section>
  );
};

const contactSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  referredBy: yup.string(),
  message: yup.string().required("Message is required"),
});

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  referredBy: "",
  message: "",
};

export default ContactUs;
*/
import React from "react";
import { IconButton, Box, Button, TextField, Typography, Paper, Grid } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik } from "formik";
import * as yup from "yup";
import emailjs from "emailjs-com";
import Kitten from "./pamukadam.jpg";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import Telephone from "@mui/icons-material/Phone";
import Mail from "@mui/icons-material/Email";

const ContactUs = () => {
  const handleFormSubmit = (values, { setSubmitting, resetForm }) => {
    emailjs
      .send("service_1erktdr", "template_dkjlssh", values, "svMpo78Iul0rfj1bm")
      .then(
        () => {
          toast.success("Form Submitted Successfully");
          resetForm();
        },
        () => {
          toast.error("Error submitting form");
        }
      );
    setSubmitting(false);
  };

  return (
    <section
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundImage: `url(${Kitten})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingBottom: "80px",
        marginTop: "10vh",
      }}
    >
      <ToastContainer position="top-center" />
      <Paper
        elevation={6}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // ✅ Stacks on mobile, row on larger screens
          width: "90%",
          maxWidth: "1000px",
          borderRadius: "12px",
          background: "transparent",
          marginTop: "50px",
          backdropFilter: {xs:"blur(10px)",md:"blur(10px)"},
          backgroundColor: { xs: "rgba(255, 255, 255, 0.5)", md: "rgba(255, 255, 255, 0.5)" }, 
          boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Contact Information Section */}
        <Box
          sx={{
            width: { xs: "100%", sm: "40%" }, // ✅ Full width on mobile, smaller on tablet/desktop
            backgroundColor: "rgba(23, 23, 26, 0.85)",
            color: "white",
            display: "flex",
            flexDirection: "column",
            padding: { xs: "30px", sm: "40px" },
            borderRadius: { xs: "12px 12px 0 0", sm: "10px" },
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "16px" }}>
            Have any questions or want more information about the surgery, Kusadasi, or anything else? 
            We are just an email or phone call away.
          </Typography>

          <Box sx={{ marginTop: "50px", cursor: "pointer" }}>
            <Typography
              variant="body1"
              sx={{
                color: "white",
                "&:hover": { color: "#007BFF" },
              }}
              onClick={() => window.open("https://www.google.com/maps?q=Gözde+Kuşadası+Hastanesi", "_blank")}
            >
              <RoomOutlinedIcon fontSize="small" /> Türkmen, Rıza Saraç Cd., 09400 Kuşadası/Aydın, Türkiye
            </Typography>
          </Box>

          <Typography variant="body1">
            <Telephone fontSize= "small" /> <a href="tel://+905465497787" style={{ color: "white" }}>+90 546 549 77 87</a>
          </Typography>
          <Typography variant="body1">
            <Mail fontSize="small" /> <a href="mailto:info@pem-health.com" style={{ color: "white" }}>info@pem-health.com</a>
          </Typography>

          {/* Social Media Icons */}
          <Box sx={{ display: "flex", gap: "15px", marginTop: "10px",    justifyContent: { xs: "center", sm: "flex-start" } }}>
            <IconButton component="a" href="https://www.facebook.com/Op.Dr.FikretBeyaz/" target="_blank" sx={{ color: "#0e76a8" }}>
              <FacebookIcon fontSize="large" />
            </IconButton>
            <IconButton component="a" href="https://www.instagram.com/dr.fikretbeyaz/?hl=en" target="_blank" sx={{ color: "#E1306C" }}>
              <InstagramIcon fontSize="large" />
            </IconButton>
          </Box>
        </Box>

        {/* Contact Form Section */}
        <Box
          sx={{
            width: { xs: "100%", sm: "60%" }, // ✅ Full width on mobile, larger on tablet/desktop
            padding: { xs: "30px", sm: "40px" },
            backdropFilter: "blur(10px)",
          }}
        >
          <Formik initialValues={initialValues} validationSchema={contactSchema} onSubmit={handleFormSubmit}>
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  {["firstName", "lastName", "email", "referredBy", "message"].map((field, index) => (
                    <Grid item xs={12} sm={index < 2 ? 6 : 12} key={field}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label={field === "referredBy" ? "Who referred you?" : field.charAt(0).toUpperCase() + field.slice(1)}
                        name={field}
                        multiline={field === "message"}
                        rows={field === "message" ? 4 : 1}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values[field]}
                        error={!!touched[field] && !!errors[field]}
                        helperText={touched[field] && errors[field]}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": { borderColor: "rgb(2, 2, 83)" },
                            "&:hover fieldset": { borderColor: "green" },
                            "&.Mui-focused fieldset": { borderColor: "rgb(2, 2, 83)" },
                            
                          },
                          "& .MuiInputLabel-root": { color: "rgb(2, 2, 83)" },
                        }}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" fullWidth sx={{
                      marginTop: "10px",
                      padding: "10px",
                      backgroundColor: "rgba(222, 207, 255, 0.54)",
                      color: "rgba(23, 23, 26, 0.85)",
                      borderRadius: "50px",
                      fontSize: "1rem",
                      fontWeight:"600",
                      cursor: "pointer",
                      transition: "background-color 0.3s, color 0.3s, transform 0.3s ease",
                      "&:hover": { backgroundColor: "#555", color: "white", transform: "scale(1.05)" },
                    }}>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Box>
      </Paper>
    </section>
  );
};

const contactSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  email: yup.string().email("Invalid email").required("Required"),
  referredBy: yup.string(),
  message: yup.string().required("Required"),
});

const initialValues = { firstName: "", lastName: "", email: "", referredBy: "", message: "" };

export default ContactUs;
