import React, { useState, useEffect, useRef, useMemo } from "react";
import { Box, IconButton, Typography, Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import path1 from "./path1.jpg";
import path2 from "./path2.jpg";
import { Link } from "react-router-dom";

const Slider = () => {
  const collapseTimeout = useRef(null);
  const isSliding = useRef(false);

  const images = [
    {
      url: path1,
      title: "Gastric Sleeve Surgery",
      description:
        "Gastric sleeve surgery is an effective and increasingly popular procedure for those seeking substantial weight loss and improved health. This innovative procedure involves the removal of a significant portion of the stomach, resulting in a smaller, banana-shaped new stomach that comprises only 15-20% of its original size.",
      link: "/procedures/gastricsleeve",
    },
    {
      url: path2,
      title: "Gastric Bypass Surgery",
      description:
        "Gastric bypass surgery is designed to reduce the amount of food a patient can consume by shrinking the stomach size and shortening the pathway food travels through the small intestine, limiting absorption. In other words, it is both restrictive and malabsorptive surgery, which helps patients to reach their weight-loss goals.",
      link: "/procedures/gastricbypass",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isClicked, setIsClicked] = useState(false);

  // Memoize the current image to prevent unnecessary re-renders
  const currentImage = useMemo(() => images[currentIndex], [currentIndex]);

  // Debounced slide change
  const nextSlide = () => {
    if (isSliding.current) return;
    isSliding.current = true;
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    setTimeout(() => (isSliding.current = false), 10);
  };

  const prevSlide = () => {
    if (isSliding.current) return;
    isSliding.current = true;
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    setTimeout(() => (isSliding.current = false), 10);
  };

  // Preload images
  useEffect(() => {
    images.forEach((image) => {
      const img = new Image();
      img.src = image.url;
    });
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: "500px",
        height: "500px",
        overflow: "hidden",
        borderRadius: "8px",
      }}
    >
      <Box
        onClick={() => window.open(currentImage.link, "_blank")}
        sx={{
          width: "100%",
          height: "100%",
          cursor: "pointer",
        }}
      >
        <Box
          component="img"
          src={currentImage.url}
          alt={`Slide ${currentIndex + 1}`}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            transition: "transform 0.5s ease-in-out",
          }}
        />
      </Box>

      <Box
        onMouseEnter={() => {
          setIsClicked(true);
          if (collapseTimeout.current) clearTimeout(collapseTimeout.current);
        }}
        onMouseLeave={() => {
          collapseTimeout.current = setTimeout(() => setIsClicked(false), 1000);
        }}
        sx={{
          position: "absolute",
          bottom: -20,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgb(225, 231, 250)",
          color: "#555",
          padding: { xs: "10px", md: "10px" },
          cursor: "pointer",
          transition: "height 1s ease-in-out, opacity 0.8s ease-in-out",
          height: isClicked ? { xs: "auto", md: "290px" } : { xs: "72px", md: "72px" },
          overflow: "hidden",
          transform: isClicked ? "translateY(-5%)" : "translateY(0)",
          transformOrigin: "bottom",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            textAlign: { xs: "center", md: "center" },
            fontSize: { xs: "1rem", sm: "1.2rem", md: "1.3rem" },
          }}
        >
          {currentImage.title}
        </Typography>

        <Box
          sx={{
            height: "2.5px",
            width: "100%",
            backgroundColor: "#555",
            marginTop: { xs: "13px", md: "5px" },
          }}
        />

        {isClicked && (
          <>
            <Typography
              variant="body2"
              sx={{
                marginTop: "5px",
                textAlign: { xs: "center", md: "left" },
                padding: { xs: "0 10px", md: "0" },
                fontSize: { xs: "1.1rem", md: "1rem" },
              }}
            >
              {currentImage.description}
            </Typography>
            <Link to={currentImage.link} style={{ textDecoration: "none", width: "100%" }}>
            <Button
              sx={{
                width: "100%",
                marginTop: "10px",
                padding: "8px 100px",
                backgroundColor: "transparent",
                color: "black",
                border: "2px solid #555",
                borderRadius: "50px",
                fontSize: { xs: "0.8rem", md: "1rem" },
                cursor: "pointer",
                transition: "background-color 0.3s, color 0.3s, transform 0.3s ease",
                "&:hover": { backgroundColor: "#555", color: "white", transform: "scale(1.01)" },
                "&:active": { transform: "scale(0.98)" },
              }}
            >
              {currentImage.title}
            </Button>
          </Link>


          </>
        )}
      </Box>

      <IconButton
        onClick={prevSlide}
        sx={{
          position: "absolute",
          top: "50%",
          left: "10px",
          transform: "translateY(-50%)",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          color: "white",
          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.6)" },
        }}
      >
        <ArrowBackIosNewIcon />
      </IconButton>

      <IconButton
        onClick={nextSlide}
        sx={{
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          color: "white",
          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.6)" },
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default Slider;



